// import { useMediaQuery } from 'react-responsive';
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { modalSetAnyWindowIsOpen } from "../../../store/actions/modal/modalActions";


export const AnyWindowIsOpenDetector = React.memo(function AnyWindowIsOpenDetector(props){
    const dispatch = useDispatch();

    const modalAddUserShowBool = useSelector((state) => state.modal.modalAddUserShowBool, shallowEqual);
    const modalAccessDataShowBool = useSelector((state) => state.modal.modalAccessDataShowBool, shallowEqual);
    const modalAccuratelyRemoveShowBool = useSelector((state) => state.modal.modalAccuratelyRemoveShowBool, shallowEqual);
    const modalAddDealerShowBool = useSelector((state) => state.modal.modalAddDealerShowBool, shallowEqual);
    const modalChangeQrCodeShowBool = useSelector((state) => state.modal.modalChangeQrCodeShowBool, shallowEqual);
    const modalDealersShowBool = useSelector((state) => state.modal.modalDealersShowBool, shallowEqual);
    const modalUserPhotoShowBool = useSelector((state) => state.modal.modalUserPhotoShowBool, shallowEqual);
    const modalContactsShowBool = useSelector((state) => state.modal.modalContactsShowBool, shallowEqual);
    const modalWhyDontIHavePhotosShowBool = useSelector((state) => state.modal.modalWhyDontIHavePhotosShowBool, shallowEqual);

    // const isMobileRedux = useSelector((state) => state.app.isMobile, shallowEqual);
    // const isMobile = useMediaQuery({ query: `(min-width: 0px) and (max-width: ${isMobileRedux})` });
    
    useEffect(() => {
        if (modalAddUserShowBool ||
            modalAccessDataShowBool ||
            modalAccuratelyRemoveShowBool ||
            modalAddDealerShowBool ||
            modalChangeQrCodeShowBool ||
            modalDealersShowBool ||
            modalUserPhotoShowBool ||
            modalContactsShowBool ||
            modalWhyDontIHavePhotosShowBool){
            dispatch(modalSetAnyWindowIsOpen(true));
            // document.body.classList.add("modalBlocOverflow");
        }
        else{
            dispatch(modalSetAnyWindowIsOpen(false));
            // document.body.classList.remove("modalBlocOverflow");
        }
    })
});