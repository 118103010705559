import React, { Fragment, useRef } from "react";
import "./finderUser.scss";

import SVG from 'react-inlinesvg';
import search from "../../media/general/search.svg";
import { panelAsyncSetCardUsersArray, panelSetSearchWord } from "../../../store/actions/panel/panelAction";
import { useDispatch } from "react-redux";

export const FinderUser = React.memo( function FinderUser(props){
    const dispatch = useDispatch();
    const errorUserRef = useRef(null);
    const searchAreaRef = useRef(null);

    function handleSearchWord(){
        var textInputTrim = searchAreaRef.current.value.trim();
        console.log(textInputTrim);
        dispatch(panelSetSearchWord(textInputTrim));
        dispatch(panelAsyncSetCardUsersArray());
    }

   
    return(
        <Fragment>
            {console.log('rerender finderUser')}
            <div className="finderUser">
                <div className="finderUser__title">Поиск</div>
                
                <div className="finderUser__block">
                    <div ref={errorUserRef} data-error="Email не найден">
                        <input
                            ref = {searchAreaRef} 
                            type="text" 
                            className="finderUser__input" 
                            placeholder="Введите email" 
                            aria-label="Recipient's email" 
                            aria-describedby="button-addon2"
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    // console.log("enter pressed");
                                    handleSearchWord();
                                    // event.target.blur();
                                }
                                }}
                        />
                    </div>
                    <div className="finderUser__buttonBlock">
                        <button onClick={handleSearchWord} className="finderUser__button" id="button-addon2"><SVG src={search} /></button>
                    </div>
                </div>
                
            </div>
        </Fragment>
    )
});