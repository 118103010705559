import React, { Fragment, useEffect } from "react";
import "./panelHeader.scss";
import styles from "./panelHeader.scss";

import SVG from 'react-inlinesvg';
import qrcode from "../../media/header/qrcode.svg";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { modalSetModalAddUserShowBool, modalSetModalChangeQrCodeShowBool, modalSetModalDealersShowBool } from "../../../store/actions/modal/modalActions";

export const PanelHeader = React.memo( function PanelHeader(props){
    const dispatch = useDispatch();

    const linkQrCode = useSelector((state) => state.panel.linkQrCode, shallowEqual);

    var QRCode = require('qrcode.react');

    // useEffect(() => {
    //     document.body.classList.add('bgBodyPanel');
    //     return () => {
    //         document.body.classList.remove('bgBodyPanel');
    //     }
    // },[]);

    useEffect(() => {
        console.log(props.authUser.role);
    },[props.authUser]);

    function openModalAddUser() {
        dispatch(modalSetModalAddUserShowBool(true))
    }

    function openModalChangeQrCode() {
        dispatch(modalSetModalChangeQrCodeShowBool(true))
    }

    function openModalDealers() {
        dispatch(modalSetModalDealersShowBool(true))
    }

    return(
        <Fragment>
            {console.log('rerender panelHeader')}
            <div className="panelHeader">
                <div className="container p-0">
                    
                    <div className="panelHeader__infoBlock">


                        {props.authUser.role === "Admin" &&
                            <>
                                <div className="panelHeader__info">
                                    <div className="panelHeader__welcomeBlock">
                                        <span className="panelHeader__text">Добро пожаловать,</span>
                                        <span className="panelHeader__email">{props.authUser.email}</span>
                                    </div>
                                    <div className="panelHeader__countDealer">общее количество дилеров: <span>{props.authUser.dealerCounter}</span></div>
                                    <div className="panelHeader__buttonAdminBlock">
                                        <div onClick={openModalAddUser} className="panelHeader__buttonAddUser">Добавить пользователя</div>
                                        <div onClick={openModalDealers} className="panelHeader__buttonAddDealer">Диллеры</div>
                                    </div>
                                </div>

                                <div onClick={openModalChangeQrCode} className="panelHeader__qrCodeBlock">

                                    {linkQrCode === "" ? 
                                        <div className="panelHeader__qrCodeBlock_null">
                                            <SVG src={qrcode} />
                                            <span>Добавить QR</span>
                                        </div>
                                    :
                                        <QRCode
                                            value={linkQrCode}
                                            height="100%"
                                            width="100%"
                                            bgColor={styles.colorBlue}
                                            fgColor={styles.colorWhite}
                                            level={"L"}
                                            includeMargin={false}
                                            renderAs={"svg"}
                                        />
                                    }

                                </div>
                            </>
                        }


                        {props.authUser.role === "Dealer" &&
                            <>
                                <div className="panelHeader__info">
                                    <div className="panelHeader__welcomeBlock">
                                        <span className="panelHeader__text">Добро пожаловать,</span>
                                        <span className="panelHeader__email">{props.authUser.email}</span>
                                    </div>
                                    <div className="panelHeader__countDealer panelHeader_dcur">общее количество пользователей: <span>{props.authUser.clientCounter}</span></div>
                                    <div className="panelHeader__buttonAdminBlock">
                                        <div onClick={openModalAddUser} className="panelHeader__buttonAddUser">Добавить пользователя</div>
                                    </div>
                                </div>

                                {linkQrCode === "" ? 
                                    ""
                                :

                                    <div className="panelHeader__qrCodeBlock panelHeader_dcur">
                                            <QRCode
                                                value={linkQrCode}
                                                height="100%"
                                                width="100%"
                                                bgColor={styles.colorBlue}
                                                fgColor={styles.colorWhite}
                                                level={"L"}
                                                includeMargin={false}
                                                renderAs={"svg"}
                                            />
                                    </div>
                                }
                            </>
                        }


                        {props.authUser.role === "Client" &&
                            <>
                                <div className="panelHeader__info">
                                    <div className="panelHeader__welcomeBlock">
                                        <span className="panelHeader__text">Добро пожаловать,</span>
                                        <span className="panelHeader__email">{props.authUser.email}</span>
                                    </div>
                                    <div className="panelHeader__countDealer">Нажмите кнопку "Обновить список" для подгрузки новых фотографий</div>
                                </div>
                            </>
                        }


                        


                    </div>

                </div>
            </div>

        </Fragment>
    )
});