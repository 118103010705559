import React, {Fragment, useEffect, useRef, useState} from "react";
import "./modalWindowAccuratelyRemove.scss";
// import styles from './modalAvatarEditDesktop.scss';


import SVG from 'react-inlinesvg';
import times from '../../media/modal/times.svg';

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';
import { modalSetModalAccuratelyRemoveShowBool, modalSetUserForModalWindow, modalSetUserForModalWindowWithPassword } from "../../../store/actions/modal/modalActions";
import { changeUserPasswordApi, deleteAllPhotoFromAdminApi, deleteAllPhotoOfUserApi, deleteClientApi, deleteDealerApi, getUserEmailPasswordApi } from "../../../services/panelRequestsService";
import { panelAsyncSetCardDealersArray, panelAsyncSetCardUsersArray, panelAsyncSetSetOfPhotos } from "../../../store/actions/panel/panelAction";
import { appAsyncSetAuthUser } from "../../../store/actions/app/appActions";


export const ModalWindowAccuratelyRemove = React.memo( function ModalWindowAccuratelyRemove(props){
    const dispatch = useDispatch();

    const modalAccuratelyRemoveShowBool = useSelector((state) => state.modal.modalAccuratelyRemoveShowBool, shallowEqual);
    const userForModalWindow = useSelector((state) => state.modal.userForModalWindow, shallowEqual);
    const typeDelete = useSelector((state) => state.modal.typeDelete, shallowEqual);


    const [headerName, setHeaderName] = useState("");
    const [text, setText] = useState("");
    const [changePassword, setChangePassword] = useState(0);

    const warningRef = useRef(null);


    function closeModalAccuratelyRemove() {
        dispatch(modalSetModalAccuratelyRemoveShowBool(false));
    }
    function closeWarning() {
        warningRef.current.classList.add('hide');
    }

    function handlerChangePassword() {
        changeUserPasswordApi(userForModalWindow.id)
            .then(response => {
                console.log(response.data);
                dispatch(modalSetUserForModalWindowWithPassword(response.data.password));
                closeModalAccuratelyRemove();
            })
            .catch(e => {
                console.log(e);
            });
    }

    function handleDelete() {
        switch (typeDelete) {
            case "User":
                deleteClientApi(userForModalWindow.id)
                    .then(response => {
                        dispatch(modalSetModalAccuratelyRemoveShowBool(false))
                        dispatch(panelAsyncSetCardUsersArray());
                        dispatch(panelAsyncSetCardDealersArray());
                        dispatch(appAsyncSetAuthUser());
                    })
                    .catch(e => {
                        console.log(e);
                    });  
                break;
            case "Dealer":
                deleteDealerApi(userForModalWindow.id)
                    .then(response => {
                        dispatch(modalSetModalAccuratelyRemoveShowBool(false))
                        dispatch(panelAsyncSetCardDealersArray());
                        dispatch(appAsyncSetAuthUser());
                    })
                    .catch(e => {
                        console.log(e);
                    });  
                break;
            case "Photo":
                deleteAllPhotoOfUserApi(userForModalWindow.id)
                    .then(response => {
                        dispatch(modalSetModalAccuratelyRemoveShowBool(false))
                        dispatch(panelAsyncSetSetOfPhotos(userForModalWindow.id));
                    })
                    .catch(e => {
                        console.log(e);
                    });  
                break;
            case "PhotoOfAll":
                deleteAllPhotoFromAdminApi()
                    .then(response => {
                        dispatch(modalSetModalAccuratelyRemoveShowBool(false))
                        window.location.reload(false);
                    })
                    .catch(e => {
                        console.log(e);
                    });  
                break;
            default:
                break;
        }
         
    }

    useEffect(() => {
        setChangePassword(0);
        if (modalAccuratelyRemoveShowBool) {
            switch (typeDelete) {
                case "User":
                    setHeaderName("Удаление пользователя");
                    setText("Вы действительно хотите удалить пользователя");
                    break;
                case "Dealer":
                    setHeaderName("Удаление дилера");
                    setText("Вы действительно хотите удалить дилера");
                    break;
                case "Photo":
                    setHeaderName("Удаление фото");
                    setText("Вы действительно хотите удалить все фото пользователя");
                    break;
                case "PhotoOfAll":
                    setHeaderName("Удаление фото всех пользователей");
                    setText("Вы действительно хотите удалить все фото безвозвратно");
                    break;
                case "ChangePassword":
                    setHeaderName("Смена пароля");
                    setText("Вы действительно хотите сменить пароль у пользователя");
                    setChangePassword(1);
                    break;
            
                default:
                    break;
            }
        }
        
    }, [modalAccuratelyRemoveShowBool]);

    
    return(
        <Fragment>
            {console.log("reload_modalWindowAccuratelyRemove")}
            <CSSTransition
                in={modalAccuratelyRemoveShowBool}
                classNames="modalWindowAccuratelyRemoveAnim"
                timeout={props.cssTransitionTimeout}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`modalWindowAccuratelyRemoveBG ${state}`}>
                        <div onClick={closeModalAccuratelyRemove} className="modalWindowAccuratelyRemoveBG__close">
                            <SVG className="modalWindowAccuratelyRemoveBG__close__button" src={times}/>
                        </div>
                        <div className="modalWindowAccuratelyRemove">

                                <span className="modalWindowAccuratelyRemove__header-name">{headerName}</span>

                                <div className="modalWindowAccuratelyRemove__text-block">
                                    <span className="modalWindowAccuratelyRemove__text">{text}</span>
                                    <span className="modalWindowAccuratelyRemove__text-user">{userForModalWindow.email} ?</span>
                                </div>

                                <div className="modalWindowAccuratelyRemove__button-block">
                                    

                                    {changePassword ?
                                        <button onClick={handlerChangePassword} className="modalWindowAccuratelyRemove__change-button">Сменить пароль</button>
                                    :
                                        <button onClick={handleDelete} className="modalWindowAccuratelyRemove__delete-button">Удалить</button>
                                    }

                                    <div onClick={closeModalAccuratelyRemove} className="modalWindowAccuratelyRemove__cancel-button">Отмена</div>

                                </div>

                                {typeDelete == "Photo" && 
                                    <div ref={warningRef} className="modalWindowAccuratelyRemove__warning-block">
                                        <div className="modalWindowAccuratelyRemove__warning-header">ВНИМАНИЕ !!!</div>
                                        <div className="modalWindowAccuratelyRemove__warning-text">Во время экзамена <b>НЕ УДАЛЯЙТЕ</b> фотографии!!!</div>
                                        <div>Это приведёт к удалению фотографий, которые еще не успели загрузиться.</div>
    
                                        <div className="modalWindowAccuratelyRemove__warning-button-block">
                                            <div onClick={closeModalAccuratelyRemove} className="modalWindowAccuratelyRemove__cancel-button">Отмена</div>
                                            <div onClick={closeWarning} className="modalWindowAccuratelyRemove__ok-button">Понятно</div>
                                        </div>
                                    </div>
                                }


                                
                        </div>
                    </div>
                }

            </CSSTransition>
        </Fragment>
    )
});