import React, {Fragment, useEffect, useRef, useState} from "react";
import "./modalWindowAddDealer.scss";
// import styles from './modalAvatarEditDesktop.scss';


import SVG from 'react-inlinesvg';
import times from '../../media/modal/times.svg';
import check from '../../media/modal/check.svg';
import eye from "../../media/singin/eye.svg";
import eyeSlash from "../../media/singin/eye-slash.svg";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';
import { modalSetModalAddDealerShowBool } from "../../../store/actions/modal/modalActions";
import { addDealerApi } from "../../../services/panelRequestsService";
import { appAsyncSetAuthUser } from "../../../store/actions/app/appActions";
import { panelAsyncSetCardDealersArray } from "../../../store/actions/panel/panelAction";


export const ModalWindowAddDealer = React.memo( function ModalWindowAddDealer(props){
    const dispatch = useDispatch();

    // const [password, setPassword] = useState("");
    // const [passwordSvg, setPasswordSvg] = useState(false);

    // const passworInputdRef = useRef(null);
    // const showPasswordRef = useRef(null);

    const inputEmailRef = useRef(null);
    const baseBlockRef = useRef(null);
    const successBlockRef = useRef(null);
    const errorEmailRef = useRef(null); 


    const modalAddDealerShowBool = useSelector((state) => state.modal.modalAddDealerShowBool, shallowEqual);

    function closeModalAddDealer() {
        dispatch(modalSetModalAddDealerShowBool(false))
    }
    
    // useEffect(() => {
    //     if (modalAddDealerShowBool){
    //         if(password){
    //             showPasswordRef.current.classList.remove("hideShowPasswordButton");
    //         }
    //         else{
    //             showPasswordRef.current.classList.add("hideShowPasswordButton");
    //         }
    //     }

    // },[password]);

    // function showPassword() {
    //     if (passworInputdRef.current.type === "password"){
    //         passworInputdRef.current.type = "text";
    //         setPasswordSvg(true);
    //     }
    //     else{
    //         passworInputdRef.current.type = "password";
    //         setPasswordSvg(false);
    //     }
    // }

    function handlerCreateDealer(event){
        event.preventDefault();
        errorEmailRef.current.classList.remove('modalWindowAddDealer_error');
        let emailValue = inputEmailRef.current.value
        if (emailValue){
            addDealerApi(emailValue)
                .then(response => {
                    successBlockRef.current.classList.remove('hide');
                    baseBlockRef.current.classList.add('hide');
                    console.log(response.data);
                    dispatch(panelAsyncSetCardDealersArray());
                    dispatch(appAsyncSetAuthUser());
                })
                .catch(e => {
                    errorEmailRef.current.classList.add('modalWindowAddDealer_error');
                    console.log(e);
                });
        }
    }

    
    return(
        <Fragment>
            {console.log("reload_modalWindowAddDealer")}
            <CSSTransition
                in={modalAddDealerShowBool}
                classNames="modalWindowAddDealerAnim"
                timeout={props.cssTransitionTimeout}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`modalWindowAddDealerBG ${state}`}>
                        <div onClick={closeModalAddDealer} className="modalWindowAddDealerBG__close">
                            <SVG className="modalWindowAddDealerBG__close__button" src={times}/>
                        </div>
                        <div className="modalWindowAddDealer">

                                <span className="modalWindowAddDealer__header-name">Добавление дилера</span>

                                <div ref = {baseBlockRef} className="modalWindowAddDealer__add-block ">
                                    <form onSubmit={handlerCreateDealer}>
                                        <div ref = {errorEmailRef} className="modalWindowAddDealer__input-block" data-error="Email не подходит">
                                            <input
                                                ref={inputEmailRef}
                                                className="modalWindowAddDealer__input"
                                                type="email"
                                                placeholder="Введите почту"
                                            />
                                            {/* <div className="modalWindowAddDealer__input-password-block">
                                                <input
                                                    ref={passworInputdRef}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    className="modalWindowAddDealer__input"
                                                    type="password"
                                                    placeholder="Введите пароль"
                                                />
                                                <div ref={showPasswordRef} onClick = {showPassword} className="showPasswordButton hideShowPasswordButton">
                                                    <SVG src={passwordSvg ? eyeSlash : eye}/>
                                                </div>
                                            </div> */}
                                        </div>

                                        <div className="modalWindowAddDealer__button-block">
                                            <div onClick={closeModalAddDealer} className="modalWindowAddDealer__cancel-button">Отмена</div>
                                            <button type = "submit" className="modalWindowAddDealer__create-button">Создать</button>
                                        </div>
                                    </form>
                                </div>
                                
                                <div ref = {successBlockRef} className="modalWindowAddDealer__success hide">
                                    <SVG className="modalWindowAddDealer__svg" src={check}/>
                                    <div className="modalWindowAddDealer__text-success">Дилер успешно добавлен, ему на почту высланы логин и пароль</div>
                                    <div onClick={closeModalAddDealer} className="modalWindowAddDealer__close-button">Закрыть</div>
                                </div>


                        </div>
                    </div>
                }

            </CSSTransition>
        </Fragment>
    )
});