import React, {Fragment, useEffect} from "react";
import "./modalWindowUserPhoto.scss";
// import styles from './modalAvatarEditDesktop.scss';


import SVG from 'react-inlinesvg';
import times from '../../media/modal/times.svg';
import syncAlt from "../../media/panel/sync-alt.svg";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';
import { modalSetModalAccuratelyRemoveShowBool, modalSetModalUserPhotoShowBool, modalSetTypeDelete } from "../../../store/actions/modal/modalActions";
import { FilePhoto } from "../../general/filePhoto/filePhoto";
import { FilePhotoGeneralized } from "../../general/filePhotoGeneralized/filePhotoGeneralized";
import { panelAsyncSetSetOfPhotos } from "../../../store/actions/panel/panelAction";


export const ModalWindowUserPhoto = React.memo( function ModalWindowUserPhoto(props){
    const dispatch = useDispatch();

    const authUser = useSelector((state) => state.app.authUser, shallowEqual);
    const spinnerSmall = useSelector((state) => state.panel.spinnerSmall, shallowEqual);
    const modalUserPhotoShowBool = useSelector((state) => state.modal.modalUserPhotoShowBool, shallowEqual);
    const userForModalWindow = useSelector((state) => state.modal.userForModalWindow, shallowEqual);
    const setOfPhotos = useSelector((state) => state.panel.setOfPhotos, shallowEqual);

    function closeModalUserPhoto() {
        dispatch(modalSetModalUserPhotoShowBool(false));
    }

    function openModalAccuratelyRemove() {
        dispatch(modalSetTypeDelete("Photo"));
        dispatch(modalSetModalAccuratelyRemoveShowBool(true));
    }
    
    function handleGetSetOfPhotos(withFlash = true){
        dispatch(panelAsyncSetSetOfPhotos(userForModalWindow.id, withFlash));
    }

    useEffect(() => {
        if (userForModalWindow && modalUserPhotoShowBool){
            handleGetSetOfPhotos(false);
        }
    }, [userForModalWindow, modalUserPhotoShowBool]);

    
    return(
        <Fragment>
            {console.log("reload_modalWindowUserPhoto")}
            <CSSTransition
                in={modalUserPhotoShowBool}
                classNames="modalWindowUserPhotoAnim"
                timeout={props.cssTransitionTimeout}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`modalWindowUserPhotoBG ${state}`}>
                        <div onClick={closeModalUserPhoto} className="modalWindowUserPhotoBG__close">
                            <SVG className="modalWindowUserPhotoBG__close__button" src={times}/>
                        </div>
                        <div className="modalWindowUserPhoto">

                                <span className="modalWindowUserPhoto__header-name">Фото пользователя</span>

                                <div className="modalWindowUserPhoto__email-and-count-photo-block">
                                    <div className="modalWindowUserPhoto__email">{userForModalWindow.email}</div>
                                    <div className="modalWindowUserPhoto__count-photo-block">
                                        <span className="modalWindowUserPhoto__count-text">Количество фото:</span>
                                        <span className="modalWindowUserPhoto__count-number">{authUser.role == "Dealer"? setOfPhotos.photoConfirmCounter:setOfPhotos.photoCounter}</span>
                                    </div>
                                    <div onClick={handleGetSetOfPhotos} className="panelUser__button-update-photos" style={{pointerEvents: spinnerSmall? "none":""}}>
                                        <SVG src={syncAlt} className={spinnerSmall ? "panelUser__show-spinner" : ""} />
                                        <span>Обновить список</span>  
                                    </div>
                                </div>

                                <div className="modalWindowUserPhoto__photo-block">
                                    {userForModalWindow &&
                                        <FilePhotoGeneralized user = {userForModalWindow}/> 
                                    }
                                </div>

                                <div className="modalWindowUserPhoto__button-block">
                                    {authUser.role == "Dealer" ?
                                        ""
                                    :
                                    <div onClick={openModalAccuratelyRemove} className="modalWindowUserPhoto__delete-all-photo-button">Удалить все фото</div>
                                    }

                                    <div onClick={closeModalUserPhoto} className="modalWindowUserPhoto__close-button">Закрыть</div>
                                    
                                </div>


                        </div>
                    </div>
                }

            </CSSTransition>
        </Fragment>
    )
});