import { APP_SET_AUTH_USER, APP_SET_PRELOAD } from './../../actions/app/appTypes';

const initialState = {
    authUser: '',
    bucketThumb: "quickshot-thumb",
    minioAddress: "https://turbo-minio.api-storage.ru:9000",
    socketAddress: "https://turbo-main.api-storage.ru:5055",
    // minioAddress: "http://192.168.1.65:9000",
    // socketAddress: "http://192.168.1.65:5055",
    preload: false
};

export const appReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case APP_SET_AUTH_USER:
            return {...state, authUser: payload.authUser };
        case APP_SET_PRELOAD:
            return {...state, preload: payload.preload };
        default:
            return state;
    }
};