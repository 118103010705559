import React, { Fragment } from "react";
import "./panelAdmin.scss";

import { FinderUser } from "../../general/finderUser/finderUser";
import { CardsByDay } from "../../general/cardsByDay/cardsByDay";
import { FilterDealer } from "../../general/filterDealer/filterDealer";
import { FilterAvailabilityPhoto } from "../../general/filterAvailabilityPhoto/filterAvailabilityPhoto";
import { Pacifier } from "../../general/pacifier/pacifier";

export const PanelAdmin = React.memo( function PanelAdmin(props){

    return(
        <Fragment>
            {console.log('rerender panelAdmin')}
            <div className="panelAdmin">
                <FinderUser/>

                <div className="panelAdmin__tagCloudUser">
                    <FilterDealer/>
                    <FilterAvailabilityPhoto/>
                </div>

                <div className="panelAdmin__title">Пользователи</div>

                {props.cardUsersArray.length === 0 ?
                    <Pacifier/>
                :
                    (props.cardsByDayArray || []).map((cards, i) => {
                    return  (   
                                <CardsByDay key = {i} cards = {cards}/>
                            )
                    })
                }



                

                
            </div>
        </Fragment>
    )
});