import React from 'react';
import "./errorNetworkConnect.scss";

const ErrorNetworkConnect = (props) => {

    return (
        <>
            <div className="errorNetworkConnect alert alert-danger" role="alert" style={{display: props.openErrorNetwork && "block" }}>
                Проблема сети. <b>Обновите страницу!</b>
            </div> 
        </>
    );
};

export default ErrorNetworkConnect;