export const MODAL_SET_MODAL_ADD_USER_SHOW_BOOL = "MODAL_SET_MODAL_ADD_USER_SHOW_BOOL";
export const MODAL_SET_MODAL_ACCESS_DATA_SHOW_BOOL = "MODAL_SET_MODAL_ACCESS_DATA_SHOW_BOOL";
export const USER_FOR_MODAL_WINDOW = "USER_FOR_MODAL_WINDOW";
export const MODAL_SET_MODAL_ACCURATELY_REMOVE_SHOW_BOOL = "MODAL_SET_MODAL_ACCURATELY_REMOVE_SHOW_BOOL";
export const MODAL_SET_TYPE_DELETE = "MODAL_SET_TYPE_DELETE";
export const MODAL_SET_MODAL_ADD_DEALERS_SHOW_BOOL = "MODAL_SET_MODAL_ADD_DEALERS_SHOW_BOOL";
export const MODAL_SET_MODAL_CHANGE_QR_CODE_SHOW_BOOL = "MODAL_SET_MODAL_CHANGE_QR_CODE_SHOW_BOOL";
export const MODAL_SET_MODAL_DEALERS_SHOW_BOOL = "MODAL_SET_MODAL_DEALERS_SHOW_BOOL";
export const MODAL_SET_MODAL_USER_PHOTO_SHOW_BOOL = "MODAL_SET_MODAL_USER_PHOTO_SHOW_BOOL";
export const MODAL_SET_MODAL_CONTACTS_SHOW_BOOL = "MODAL_SET_MODAL_CONTACTS_SHOW_BOOL";
export const MODAL_SET_MODAL_WHY_DONT_I_HAVE_PHOTOS_SHOW_BOOL = "MODAL_SET_MODAL_WHY_DONT_I_HAVE_PHOTOS_SHOW_BOOL";
export const MODAL_SET_ANY_WINDOW_IS_OPEN = "MODAL_SET_ANY_WINDOW_IS_OPEN";