import React, { Fragment, useEffect, useRef } from "react";
import "./filePhoto.scss";

// import SVG from 'react-inlinesvg';
// import trash from "../../media/panel/trash.svg";

import image from "../../media/general/image.png"
import { toDateTime } from "../functions";
import { getImageUrlApi } from "../../../services/panelRequestsService";
import { shallowEqual, useSelector } from "react-redux";

export const FilePhoto = React.memo( function FilePhoto(props){

    const bucketThumb = useSelector((state) => state.app.bucketThumb, shallowEqual);
    const minioAddress = useSelector((state) => state.app.minioAddress, shallowEqual);

    const filePhotoRef = useRef(null); 

    function handleOpenImageInNewWindow(){
        let windowReference = window.open();
        getImageUrlApi(props.photo.setOfPhotos, props.photo.id)
            .then(response => {
                console.log(response.data.url);
                // window.open(response.data.url, "_blank");
                windowReference.location = response.data.url;
            })
            .catch(e => {
                console.log(e);
            });
    }

    useEffect(() => {
        if(props.photo.flash){
            filePhotoRef.current.classList.add("filePhoto_new")
        }
    }, [props.photo.flash]);

    function classForFlashDelete() {
        filePhotoRef.current.classList.remove("filePhoto_new")
    }

    useEffect(() => {
        if (!props.photo.confirmed){
            filePhotoRef.current.classList.add('filePhoto__not-confirmed');
        }
        else{
            filePhotoRef.current.classList.remove('filePhoto__not-confirmed');
        }
    }, [props.photo.confirmed]);

  
    return(
        <Fragment>
            {console.log('rerender filePhoto')}
            <div ref = {filePhotoRef} onAnimationEnd={classForFlashDelete} className="filePhoto">
                <div className="filePhoto__row">
                    <div onClick={handleOpenImageInNewWindow} className="filePhoto__img-block">
                        <div className="filePhoto__img">
                            <img src={props.photo.previewImageUrl? encodeURI(minioAddress + "/" + bucketThumb + "/" + props.photo.previewImageUrl + props.photo.setOfPhotos + '/' + props.photo.identifier + '/' + props.photo.name) : image} />
                        </div>
                    </div>
                    <div className="filePhoto__info-block">
                        <div className="filePhoto__file-name">{props.photo.name}</div>
                        <div className="filePhoto__data-and-time">{toDateTime(props.photo.created)}</div>
                    </div>
                    <div className="filePhoto__button-block">
                        <div onClick={handleOpenImageInNewWindow} className="filePhoto__button">Открыть</div>
                    </div>
                </div>
            </div>
            
        </Fragment>
    )
});

