import { addClientApi, getClientsApi, getDealersApi, getLinkQrCodeApi, getSetOfPhotosApi } from "../../../services/panelRequestsService"
import { PANEL_SET_AVAILABILITY_PHOTO, PANEL_SET_BUTTON_SHOW_ALL, PANEL_SET_CARD_DEALERS_ARRAY, PANEL_SET_CARD_USERS_ARRAY, PANEL_SET_DEALER_SELECT, PANEL_SET_LINK_QR_CODE, PANEL_SET_SEARCH_WORD, PANEL_SET_SET_OF_PHOTOS, PANEL_SET_SPINNER_SMALL } from "./panelTypes"
import update from 'immutability-helper';

export const panelSetCardUsersArray = (cardUsersArray) =>{
    return{
        type: PANEL_SET_CARD_USERS_ARRAY,
        payload:{
            cardUsersArray
        }
    }

}

export const panelSetCardDealersArray = (cardDealersArray) =>{
    return{
        type: PANEL_SET_CARD_DEALERS_ARRAY,
        payload:{
            cardDealersArray
        }
    }

}

export const panelSetSetOfPhotos = (setOfPhotos) =>{
    return{
        type: PANEL_SET_SET_OF_PHOTOS,
        payload:{
            setOfPhotos
        }
    }

}

export const panelSetlinkQrCode = (linkQrCode) =>{
    return{
        type: PANEL_SET_LINK_QR_CODE,
        payload:{
            linkQrCode
        }
    }
}

export const panelSetSearchWord = (searchWord) =>{
    return{
        type: PANEL_SET_SEARCH_WORD,
        payload:{
            searchWord
        }
    }
}

export const panelSetDealerSelect = (dealerSelect) =>{
    return{
        type: PANEL_SET_DEALER_SELECT,
        payload:{
            dealerSelect
        }
    }
}

export const panelSetAvailabilityPhoto = (availabilityPhoto) =>{
    return{
        type: PANEL_SET_AVAILABILITY_PHOTO,
        payload:{
            availabilityPhoto
        }
    }
}

export const panelSetSpinnerSmall = (spinnerSmall) =>{
    return{
        type: PANEL_SET_SPINNER_SMALL,
        payload:{
            spinnerSmall
        }
    }
}

export const panelSetButtonShowAll = (buttonShowAll) =>{
    return{
        type: PANEL_SET_BUTTON_SHOW_ALL,
        payload:{
            buttonShowAll
        }
    }
}

export const panelAsyncSetCardUsersArray = () => {
    return function (dispatch, getState){
        setTimeout(() => {
            getClientsApi(getState().panel.searchWord, getState().panel.dealerSelect, getState().panel.availabilityPhoto)
                .then(response => {
                    dispatch(panelSetCardUsersArray(response.data));
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                });
        }, 1);
    }
}

export const panelAsyncSetCardDealersArray = () => {
    return function (dispatch){
        getDealersApi()
            .then(response => {
                dispatch(panelSetCardDealersArray(response.data));
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }
}

export const panelAsyncSetSetOfPhotos = (userId, withFlash = true, showAll = false) => {
    return function (dispatch, getState){
        var numberOfPhotoHighlight = 0;
        var photoConfirmCounterOld = -1;
        dispatch(panelSetSpinnerSmall(true));
        if (withFlash){
            if (getState().panel.setOfPhotos){
                photoConfirmCounterOld = getState().panel.setOfPhotos.photoConfirmCounter;
            }
        }
        else{
            dispatch(panelSetSetOfPhotos(""));
        }
        
        getSetOfPhotosApi(userId, showAll)
            .then(response => {
                if (response.data.length > 0){
                    var setOfPhotos = response.data[0]
                }
                else{
                    var setOfPhotos = response.data
                }
                setOfPhotos.photos = setOfPhotos.photos.reverse()
                if (photoConfirmCounterOld != -1 && (setOfPhotos.photoConfirmCounter - photoConfirmCounterOld) > 0){
                    numberOfPhotoHighlight = setOfPhotos.photoConfirmCounter - photoConfirmCounterOld
                }
                if (numberOfPhotoHighlight){
                    for (let index = 0; index < setOfPhotos.photos.length; index++) {
                        setOfPhotos.photos[index]["flash"] = true;
                        if (index == numberOfPhotoHighlight - 1){
                            break;
                        }
                    }
                }
                console.log(photoConfirmCounterOld, setOfPhotos.photoConfirmCounter)
                setOfPhotos['countPhotoFromServer'] = 0
                dispatch(panelSetSetOfPhotos(setOfPhotos));
                setTimeout(() => {
                    dispatch(panelSetSpinnerSmall(false));
                }, 10000);

                console.log(response.data);
                if (showAll){
                    dispatch(panelSetButtonShowAll(false));
                }
                else{
                    if (setOfPhotos.photoCounter > 30){
                        dispatch(panelSetButtonShowAll(true));
                    }
                    else{
                        dispatch(panelSetButtonShowAll(false));
                    }
                }
            })
            .catch(e => {
                setTimeout(() => {
                    dispatch(panelSetSpinnerSmall(false));
                }, 10000);
                console.log(e);
            });
    }
}

export const panelAsyncSetLinkQrCode = () => {
    return function (dispatch){
        getLinkQrCodeApi()
            .then(response => {
                dispatch(panelSetlinkQrCode(response.data.url));
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }
}

export const panelSetCountPhotoFromServerInSetOfPhotos = (msg, role) =>{
    return function (dispatch, getState){
        var newSetOfPhotos = getState().panel.setOfPhotos;
        var photos = newSetOfPhotos.photos;
        const i = photos.findIndex(x => x.identifier === msg.photoIdentifier);
        if (i !== -1){
            if (photos[i]["confirmed"]){
                const newData = update(newSetOfPhotos, {
                    photos:{[i]:{previewImageUrl:{$set: msg.path}}}
                });
                dispatch(panelSetSetOfPhotos(newData));
                return;
            }
        }
        var countPhotoFromServer = msg.countPhotoConfirmFromServer
        if (role == "Admin"){
            countPhotoFromServer = msg.countPhotoFromServer
        }
        const newData = update(newSetOfPhotos, {
            countPhotoFromServer:{$set: countPhotoFromServer}
        });
        dispatch(panelSetSetOfPhotos(newData));
    }
}





