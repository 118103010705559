import React, { Fragment } from "react";
import "./panelDealer.scss";

import { FinderUser } from "../../general/finderUser/finderUser";
import { CardsByDay } from "../../general/cardsByDay/cardsByDay";
import { Pacifier } from "../../general/pacifier/pacifier";
import { FilterAvailabilityPhoto } from "../../general/filterAvailabilityPhoto/filterAvailabilityPhoto";

export const PanelDealer = React.memo( function PanelDealer(props){
   
    return(
        <Fragment>
            {console.log('rerender panelDealer')}
            <div className="panelDealer">

                <FinderUser/>

                <FilterAvailabilityPhoto/>

                <div className="panelDealer__title">Пользователи</div>

                {props.cardUsersArray.length === 0 ?
                    <Pacifier/>
                :
                    (props.cardsByDayArray || []).map((cards, i) => {
                    return  (   
                                <CardsByDay key = {i} cards = {cards}/>
                            )
                    })
                }   
                

            </div>
        </Fragment>
    )
});