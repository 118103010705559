import React, { Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { panelAsyncSetCardUsersArray, panelSetAvailabilityPhoto } from "../../../store/actions/panel/panelAction";

export const FilterAvailabilityPhoto = React.memo( function FilterAvailabilityPhoto(props){
    const dispatch = useDispatch();
    const availabilityPhoto = useSelector((state) => state.panel.availabilityPhoto, shallowEqual);

    function handleSetAvailabilityPhoto(value){
        console.log('value', value)
        dispatch(panelSetAvailabilityPhoto(value));
        dispatch(panelAsyncSetCardUsersArray());
    }

    return(
        <Fragment>
            {console.log('rerender filterAvailabilityPhoto')}

            <div className="panelAdmin__title">Наличие Фото</div> 
            <form className="panelAdmin__form">
                
                <input hidden id="photoAll" type="radio" name="photoRadio" value="all" onChange={(e) => handleSetAvailabilityPhoto(e.currentTarget.value)} checked = {availabilityPhoto == "all"}/>
                <label htmlFor="photoAll" className="panelAdmin__radioButton">Неважно</label>
            
                <input hidden id="photoActive" type="radio" name="photoRadio" value="active" onChange={(e) => handleSetAvailabilityPhoto(e.currentTarget.value)} checked = {availabilityPhoto == "active"}/>
                <label htmlFor="photoActive" className="panelAdmin__radioButton">Сегодня фоткали</label>
            
                <input hidden id="photoExist" type="radio" name="photoRadio" value="exist" onChange={(e) => handleSetAvailabilityPhoto(e.currentTarget.value)} checked = {availabilityPhoto == "exist"}/>
                <label htmlFor="photoExist" className="panelAdmin__radioButton">Есть фото</label>

                <input hidden id="photoMissing" type="radio" name="photoRadio" value="missing" onChange={(e) => handleSetAvailabilityPhoto(e.currentTarget.value)} checked = {availabilityPhoto == "missing"}/>
                <label htmlFor="photoMissing" className="panelAdmin__radioButton">Нет фото</label>
            </form>

        </Fragment>
    )
});