import React, { Fragment } from "react";
import './loading.scss';
import styles from "./loading.scss";
import ReactLoading from 'react-loading';
import { shallowEqual, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';

export const Loading = React.memo( function Loading(props){

    const preload = useSelector((state) => state.app.preload, shallowEqual);

    return(
        <Fragment>
            {console.log('rerender loading')}

            <CSSTransition
                in={preload}
                classNames="loadingAnim"
                timeout={340}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`loading ${state}`} >
                        <ReactLoading type={"spinningBubbles"} color={styles.colorBlue} height={'100px'} width={'100px'} delay={100} />
                    </div>
                }
            </CSSTransition>

            

        </Fragment>
    )
});