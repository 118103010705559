import React, { Fragment, useEffect, useState } from "react";

import { PanelHeader } from "./panelHeader/panelHeader";
import { PanelAdmin } from "./panelAdmin/panelAdmin";
import { PanelUser } from "./panelUser/panelUser";
import { PanelDealer } from "./panelDealer/panelDealer";
import { ModalWindowAddUser } from "../modalWindow/modalWindowAddUser/modalWindowAddUser";
import { ModalWindowAddDealer } from "../modalWindow/modalWindowAddDealer/modalWindowAddDealer";
import { ModalWindowAccessData } from "../modalWindow/modalWindowAccessData/modalWindowAccessData";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { panelAsyncSetCardDealersArray, panelAsyncSetCardUsersArray, panelAsyncSetLinkQrCode } from "../../store/actions/panel/panelAction";
import { ModalWindowChangeQrCode } from "../modalWindow/modalWindowChangeQrCode/modalWindowChangeQrCode";
import { ModalWindowAccuratelyRemove } from "../modalWindow/modalWindowAccuratelyRemove/modalWindowAccuratelyRemove";
import { ModalWindowUserPhoto } from "../modalWindow/modalWindowUserPhoto/modalWindowUserPhoto";
import { ModalWindowDealers } from "../modalWindow/modalWindowDealers/modalWindowDealers";
import { ModalWindowWhyDontIHavePhotos } from "../modalWindow/modalWindowWhyDontIHavePhotos/modalWindowWhyDontIHavePhotos";
import { areTheTwoDaysDifferent } from "../general/functions";

export const PanelMaster = React.memo( function PanelMaster(props){
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.app.authUser, shallowEqual);
    const cardUsersArray = useSelector((state) => state.panel.cardUsersArray, shallowEqual);
    
    const cssTransitionTimeout = useSelector((state) => state.modal.cssTransitionTimeout, shallowEqual);

    const [cardsByDayArray, setCardsByDayArray] = useState([]);

    function handleGetCardUsersArray(){
        dispatch(panelAsyncSetCardUsersArray());
        if (authUser.role != "Dealer"){
            dispatch(panelAsyncSetCardDealersArray());
        }
        dispatch(panelAsyncSetLinkQrCode());
    }

    useEffect(() => {
        if (authUser && authUser.role != "Client"){
            handleGetCardUsersArray();
        }
        setTimeout(() => {
            document.body.classList.add('bgBodyPanel');
        }, 100);
        
        return () => {
            document.body.classList.remove('bgBodyPanel');
        }
    },[authUser]);



    useEffect(() => {
        if (cardUsersArray.length > 0){
            let tmpFirstArray = []
            let tmpSecondArray = []
            for (let i = 0; i < cardUsersArray.length; i++) {
                if (i > 0){
                    if (areTheTwoDaysDifferent(cardUsersArray[i-1].created, cardUsersArray[i].created)){
                        tmpFirstArray.push(tmpSecondArray)
                        tmpSecondArray = []
                    }
                }
                tmpSecondArray.push(cardUsersArray[i]);
            }
            tmpFirstArray.push(tmpSecondArray)

            setCardsByDayArray(tmpFirstArray);
        }
        else{
            setCardsByDayArray([]);
        }
    },[cardUsersArray]);
        

    return(
        <Fragment>
            {console.log('rerender panelMaster')}
            <div className="container-fluid panelMaster">
                <div className="container p-0">

                    
                    {authUser &&
                        <>
                            <PanelHeader authUser = {authUser}/>
                            {authUser.role === "Admin" &&
                                <PanelAdmin 
                                    cardsByDayArray = {cardsByDayArray}
                                    cardUsersArray = {cardUsersArray}
                                />
                            }

                            {authUser.role === "Dealer" &&
                                <PanelDealer 
                                    cardsByDayArray = {cardsByDayArray}
                                    cardUsersArray = {cardUsersArray}
                                />
                            }

                            {authUser.role === "Client" &&
                                <PanelUser authUser = {authUser}/>
                            }
                        </>
                    }

                    <ModalWindowAddUser
                        cssTransitionTimeout = {cssTransitionTimeout}
                    />
                    <ModalWindowAddDealer
                        cssTransitionTimeout = {cssTransitionTimeout}
                    />
                    <ModalWindowAccessData
                        cssTransitionTimeout = {cssTransitionTimeout}
                    />
                    <ModalWindowChangeQrCode
                        cssTransitionTimeout = {cssTransitionTimeout}
                    />
                    <ModalWindowAccuratelyRemove
                        cssTransitionTimeout = {cssTransitionTimeout}
                    />
                    <ModalWindowUserPhoto
                        cssTransitionTimeout = {cssTransitionTimeout}
                    />
                    <ModalWindowDealers
                        cssTransitionTimeout = {cssTransitionTimeout}
                        authUser = {authUser}
                    />
                    <ModalWindowWhyDontIHavePhotos
                        cssTransitionTimeout = {cssTransitionTimeout}
                    />

                    
                    {/* <PanelAdmin/>
                    <PanelUser/>
                    <PanelDealer/> */}

                </div>
            </div>

        </Fragment>
    )
});