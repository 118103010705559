import React, { Fragment} from "react";

export const Instruction = React.memo( function Instruction(props){

    const thisIsMyCopy = `
<!DOCTYPE html>
<html lang="ru">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Инструкция по использованию программы «Quick Shot»</title>
</head>
<style>
    .body{
        display: flex;
        justify-content: center;
        background-color: #eee;  
    }
    .main{
        max-width: 700px;
    /* background-color: chartreuse; */  
    }
    .img{
        width: 30px; 
        height: 30px; 
        margin: 0 5px;
    }
</style>
<body class="body">
    <div class="main" class="main">
        <!-- <h3>Обновление программы «Quick Shot»:</h3>
        <ol>
            <li>Зайдите в «App Store»<img src="/img/AppStore.png" class="img" alt="AppStore">и скачайте приложение «TestFlight»<img src="/img/Test.png" class="img" alt="TestFlight"></li>
            <li>Откройте приложение «TestFlight»<img src="/img/Test.png" class="img" alt="TestFlight">и обновите «Quick Shot»<img src="/img/icon.png" class="img" alt="Quick Shot"></li>
        </ol>
        <br> -->
        <h3 style="color: red;">ПРЕДУПРЕЖДЕНИЕ!!!!</h3>
        <ol>
            <li>Звонить только по сотовой связи (не использовать WhatsApp, Telegram и т.п.)</li>
            <li>VPN должен быть выключен</li>
            <li>Нельзя одновременно фотографировать и быть на связи. Помощник должен положить трубку, чтобы вы продолжили фотографировать</li>
            <li>Обязательно ждите второй вибрации! (актуально для тех кто устанавливал приложение до мая 2024 года)</li>
            <li>Если телефон зависает после звонка, тогда верните вызовы в баннерный режим: «Настройки» – «Телефон» - «Входящие вызовы» (поставить «Баннер»)</li>
        </ol>
        <ol>
            <b>Актуально для тех кто устанавливал приложение с мая 2024 года</b>
            <li>Если сетевое соединение улучшится и приложение открыто, ранее не отправленные фотографии продолжат отправляться. Если вы хотите остановить отправку, перезапустите приложение.</li>
        </ol>
        <br>
        
        
        <h3>Инструкция по использованию программы «Quick Shot»:</h3>
        <ol>
            <li>Откройте «Quick Shot», введите свою электронную почту и нажмите кнопку «Сделать фото».</li>
            <li>Сделайте снимок коротким нажатием на кнопку увеличения громкости (или bluetooth кнопки) и ждите.</li>
            <li>Первая вибрация сообщает о том, что фотография сохранена в телефоне (если вибрации нет, включите её в настройках: «Настройки» – «Звуки» – «Вибрация»).</li>
            <li>Вторая вибрация сообщает о том, что фотография отправлена.</li>
            <li>После второй вибрации можно делать следующий снимок. (актуально для тех кто устанавливал приложение до мая 2024 года)</li>
        </ol>
        <br>
        <h3>Инструкция для просмотра фотографий, сделанных при помощи программы «Quick Shot»:</h3>
        <p style="color: red;">ВАЖНО! Фотографии не приходят на почту!</p>
        <ol>
            <li>Зайдите на сайт <a href="http://quick-shot.ru/">http://quick-shot.ru/</a></li>
            <li>В правом верхнем углу нажмите кнопку «Войти».</li>
            <li>На открывшейся странице введите свою электронную почту, которую вы указывали в приложении и пароль, который вам выдали при установке.</li>
            <li>Откроется список ваших фотографий. Для просмотра фотографии нажмите, находящуюся напротив неё, кнопку «Открыть».</li>
            <li>Для обновления списка фотографий нажмите кнопку «Обновить список».</li>
        </ol>
        <br>
        
        <h3>Настройка телефона (обязательно):</h3>
        <ol>
            <li>Выключить автоблокировку: «Настройки» – «Экран и яркость» – «Автоблокировка» (поставить «Никогда»)</li>
            <li>Выключить пункт управления: «Настройки» – «Пункт управления» - «Доступ в приложениях»</li>
            <li>Выключить уведомления: «Настройки» – «Уведомления» - «Показ миниатюр» (поставить «Никогда»)</li>
        </ol>
        <h3>Настройка телефона (если нужно):</h3>
        <ol>
            <li>Включить функцию автоответ: «Настройки» – «Универсальный доступ» – «Касание» – «Источник звука» - «Автоответ на вызовы»</li>
        </ol>
        <br>

        <h3>Проверка скорости интернета </h3>
        <ol>
            <li>Скачайте приложение SpeedTest (<a href="https://apps.apple.com/ru/app/speedtest-by-ookla/id300704847" target="_blank">https://apps.apple.com/ru/app/speedtest-by-ookla/id300704847</a>) </li>
            <li>Нажмине на кнопку «Начать» или «Go» и ждите пока пройдет тест</li>
            <li>Когда тест пройден, посмотрите какая скорость у Вас на «Загрузить» или «Upload» если она меньше 1 мбит/с, то необходимо поменять оператора (оптимальная скорость загрузки 4 – 5 мбит/с)</li>
        </ol>
        <br>
        
    </div>
</body>
</html>
    `;
    return(
        <Fragment>
            <div style ={{display: "flex", alignItems: "center", justifyContent: "center", padding: "15px"}} dangerouslySetInnerHTML={{ __html: thisIsMyCopy }}></div>
        </Fragment> 
    )
});