import React, { useEffect, useRef } from "react";

import {
  BrowserRouter as Router, Route,
  useLocation, useHistory, Switch
} from "react-router-dom";

import { WelcomePage } from "./components/welcomePage/welcomePage";
import { Header } from "./components/header/header";
import { Signin } from "./components/signIn/signIn";
import { PanelMaster } from "./components/panel/panelMaster";
import { RemoveScroll } from "react-remove-scroll";
import { AnyWindowIsOpenDetector } from "./components/general/anyWindowIsOpen/anyWindowIsOpen";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Loading } from "./components/general/loading/loading";
import { appSetPreload } from "./store/actions/app/appActions";
import { modalSetCloseAllModalWindow } from "./store/actions/modal/modalActions";
import { Instruction } from "./components/instruction/instruction";

const App = () => {
  const dispatch = useDispatch();

  let location = useLocation();

  const RemoveScrollRef = useRef(null);

  const authUser = useSelector((state) => state.app.authUser, shallowEqual);
  const preload = useSelector((state) => state.app.preload, shallowEqual);
  const anyWindowIsOpen = useSelector((state) => state.modal.anyWindowIsOpen, shallowEqual);

  const history = useHistory();


  // useEffect(() => {
  //   if (location.pathname === "/panel"){
  //     dispatch(appSetPreload(true));
  //     RemoveScrollRef.current.classList.add('hide');
  //   }
  // },[location.pathname]);

  // useEffect(() => {
  //   if (preload){
  //     setTimeout(() => {
  //       dispatch(appSetPreload(false));
  //       RemoveScrollRef.current.classList.remove('hide');
  //     }, 1000);
  //     RemoveScrollRef.current.classList.remove('hide');
  //     document.body.classList.add('overflow-hide');
  //   }
  //   else{
  //     document.body.classList.remove('overflow-hide');
  //   }
  // }, [preload]);
  

//------------------------------------------------- Closing windows on return--------------------------------------------------------------
  useEffect(() => {
    // dispatch(feedSetLastModAvatarImage(Date.now()));
    if (history.action === "POP") {
      // Do your stuff
      dispatch(modalSetCloseAllModalWindow());
    }
  },[location])

  useEffect(() => {
    dispatch(modalSetCloseAllModalWindow());
    console.log('%c%s', 'color: green; font: 1.2rem/1 Tahoma;','change location.pathname');
  },[location.pathname])
  

useEffect(() => {
  console.log(anyWindowIsOpen, 'anyWindowIsOpen');
  if (anyWindowIsOpen){
    history.push(location.pathname, { from: 'anyWindowIsOpen' });
  }
  else{
    if (history.location.state){
      if (history.location.state.from == 'anyWindowIsOpen'){
        history.goBack();
      }
    }
  }
  console.log(history.length); 
},[anyWindowIsOpen])

//------------------------------------------------- Closing windows on return--------------------------------------------------------------

  return(
          <>
            {console.log('reload_app')}
              {location.pathname == "/panel" && 
                <Loading/>
              }

                <RemoveScroll ref={RemoveScrollRef} enabled={anyWindowIsOpen} className="w-100">
                  <AnyWindowIsOpenDetector/>
                  <Header pathname = {location.pathname} />
                    <Switch>
                      <Route path="/" exact component = {WelcomePage}></Route>
                      <Route path="/signin" exact component = {Signin}></Route>
                      <Route path="/panel" exact component = {PanelMaster}></Route>
                      <Route path="/instruction" exact component = {Instruction}></Route>
                      <Route path="/instruction.html" exact component = {Instruction}></Route>
                    </Switch>
                </RemoveScroll>
          </>
      );
  }
export default App;
