import React, {Fragment, useRef} from "react";
import "./modalWindowAddUser.scss";
// import styles from './modalAvatarEditDesktop.scss';


import SVG from 'react-inlinesvg';
import times from '../../media/modal/times.svg';
import check from '../../media/modal/check.svg';

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';
import { modalSetModalAddUserShowBool } from "../../../store/actions/modal/modalActions";
import { addClientApi } from "../../../services/panelRequestsService";
import { panelAsyncSetCardUsersArray } from "../../../store/actions/panel/panelAction";
import { appAsyncSetAuthUser } from "../../../store/actions/app/appActions";



export const ModalWindowAddUser = React.memo( function ModalWindowAddUser(props){
    const dispatch = useDispatch();

    const modalAddUserShowBool = useSelector((state) => state.modal.modalAddUserShowBool, shallowEqual);

    const inputEmailRef = useRef(null);
    const baseBlockRef = useRef(null);
    const successBlockRef = useRef(null);
    const errorEmailRef = useRef(null); 

    function closeModalAddUser() {
        dispatch(modalSetModalAddUserShowBool(false))
        dispatch(panelAsyncSetCardUsersArray());
    }

    function handlerCreateClient(event){
        event.preventDefault();
        errorEmailRef.current.classList.remove('modalWindowAddUser_error');
        let emailValue = inputEmailRef.current.value
        if (emailValue){
            addClientApi(emailValue)
                .then(response => {
                    dispatch(appAsyncSetAuthUser());
                    successBlockRef.current.classList.remove('hide');
                    baseBlockRef.current.classList.add('hide');
                    console.log(response.data);
                })
                .catch(e => {
                    errorEmailRef.current.classList.add('modalWindowAddUser_error');
                    console.log(e);
                });
        }
    }

    
    return(
        <Fragment>
            {console.log("reload_modalWindowAddUser")}
            <CSSTransition
                in={modalAddUserShowBool}
                classNames="modalWindowAddUserAnim"
                timeout={props.cssTransitionTimeout}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`modalWindowAddUserBG ${state}`}>
                        <div onClick={closeModalAddUser} className="modalWindowAddUserBG__close">
                            <SVG className="modalWindowAddUserBG__close__button" src={times}/>
                        </div>
                        <div className="modalWindowAddUser">

                                <span className="modalWindowAddUser__header-name">Добавление пользователя</span>

                                <div ref = {baseBlockRef} className="modalWindowAddUser__add-block ">
                                    <form onSubmit={handlerCreateClient}>
                                        <div ref = {errorEmailRef} className="modalWindowAddUser__input-block" data-error="Email не подходит">
                                            <input
                                                ref = {inputEmailRef}
                                                className="modalWindowAddUser__input"
                                                type="email"
                                                placeholder="Введите почту"
                                            />
                                        </div>

                                        <div className="modalWindowAddUser__button-block">
                                            <div onClick={closeModalAddUser} className="modalWindowAddUser__cancel-button">Отмена</div>
                                            <button type = "submit" className="modalWindowAddUser__create-button">Создать</button>
                                        </div>
                                    </form>
                                </div>
                                
                                <div ref = {successBlockRef} className="modalWindowAddUser__success hide">
                                    <SVG className="modalWindowAddUser__svg" src={check}/>
                                    <div className="modalWindowAddUser__text-success">Пользователь успешно добавлен, ему на почту высланы логин и пароль</div>
                                    <div onClick={closeModalAddUser} className="modalWindowAddUser__close-button">Закрыть</div>
                                </div>


                        </div>
                    </div>
                }

            </CSSTransition>
        </Fragment>
    )
});