import React, { Fragment, useEffect, useRef, useState } from "react";
import "./welcomePage.scss";
// import { Link } from "react-router-dom";

import SVG from 'react-inlinesvg';
import logo from "../media/header/logo_2.svg";
import mainScreen from "../media/welcomePage/main-screen.png";
import advantages1 from "../media/welcomePage/1.png";
import advantages2 from "../media/welcomePage/2.png";
import advantages3 from "../media/welcomePage/3.png";
import man from "../media/welcomePage/man.png";
import roc from "../media/welcomePage/roc.png";
import cloud from "../media/welcomePage/cloud.png";
import { modalSetModalContactsShowBool } from "../../store/actions/modal/modalActions";
import { useDispatch } from "react-redux";
import { ModalWindowContacts } from "../modalWindow/modalWindowContacts/modalWindowContacts";

export const WelcomePage = React.memo( function WelcomePage(props) {

    const dispatch = useDispatch();

    const itemEls = useRef({});


    // const sizeWindow = useWindowSize();

    // function useWindowSize() {
    //     const [size, setSize] = useState([window.innerWidth])

    //     const handleResize = () => {
    //       setSize([window.innerWidth])
    //     }
      
    //     useEffect(() => {
    //       window.addEventListener('resize', handleResize)
    //       return () => window.removeEventListener('resize', handleResize)
    //     }, [size])
      
    //     return size
    // }

    

    function toggleTopicAnswer(e) {
        e.currentTarget.classList.toggle('welcome-page__faq-topic-show');
        e.currentTarget.querySelector('.welcome-page__topic-question').classList.toggle('welcome-page__topic-question-show');
        
    }

    const questionMas = [
        {
            question: "А можно установить на Android?",
            answer: "Нет! Приложение работает только на IOS (iphone)."
        },
        {
            question: "Какие модели поддерживаются?",
            answer: "Начиная с iphone 6s и выше."
        },
        {
            question: "Для работы нужен интернет?",
            answer: "Конечно, без интернета фотографии не смогут загрузиться в облако."
        },
        {
            question: "Как можно установить приложение?",
            answer: "Свяжитесь с нами."
        },
    ];


    useEffect(() => {
        const addHeight = 30;
        if (window.innerWidth < 500){
            for (let index = 0; index < questionMas.length; index++) {
                if (itemEls.current[index].childNodes[0].clientHeight > 24){
                    itemEls.current[index].style.maxHeight = itemEls.current[index].childNodes[0].clientHeight + addHeight + "px";
                }
            }
        }
    }, [window.innerWidth]);
    



    const AdvantagesListItem = ( {titleText, contentText, image, number} ) => {
        return (
            <>
                <li className="welcome-page__advantages-item">
                    <div className="welcome-page__advantages-item-content">
                        <div className="welcome-page__advantages-image-block">
                            <img src={image} alt="" />
                        </div>
                        <div className="w-100">
                            <p className="welcome-page__header welcome-page__title-h3 welcome-page__advantages-item-title">
                                {titleText}
                            </p>
                            <p className="welcome-page__item-text welcome-page__description-text">
                                {contentText}
                            </p>
                        </div>
                    </div>
                    <div className="welcome-page__advantages-count">{number}</div>
                </li>
            </>
        )
    }

    const FaqListItem = ( {question, answer, refItem} ) => {
        return (
            <>
                <article ref={refItem} onClick={(e) => toggleTopicAnswer(e)} className="welcome-page__faq-topic">
                    <div className="welcome-page__header welcome-page__description-text welcome-page__topic-question">
                        {question}
                    </div>
                    <span className="welcome-page__topic-answer">
                        {answer}
                    </span>
                </article>
            </>
        )
    }

    function openModalContacts() {
        dispatch(modalSetModalContactsShowBool(true));
    }

    return(
        <Fragment>

            {console.log("rerender WelcomePage")}

            {/* Main description start */}

            <div className="container-fluid welcome-page__section">
                <div className="container p-0">
                    <div className="welcome-page__main-description">
                        <h1 className="welcome-page__header welcome-page__title-h1">
                            Автоматическая отправка фото
                        </h1>
                        <p className="welcome-page__description-text welcome-page__sub-title">
                            Quick Shot автоматически отправит самые дорогие и важные Вам фотографии в облако.
                        </p>
                        <div className="welcome-page__image-block">
                            <img className="welcome-page__main-image" src={mainScreen} alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            {/* Main description end */}

            {/* How work start */}

            <div className="container-fluid welcome-page__section">
                <div className="container p-0">
                    <div className="welcome-page__howWork">
                        <h2 className="welcome-page__header welcome-page__title-h2 welcome-page__howWork-title">
                                Как работает приложение Quick Shot
                        </h2>
                        <p className="welcome-page__description-text welcome-page__howWork-description">
                            Всего три простых шага и все Ваши фотографии будут находиться в облаке. К ним будет доступ с любого места и устройства.
                        </p>
                    </div>
                </div>
            </div>

            {/* How work end */}

            {/* Advantages start */}

            <div className="container-fluid welcome-page__section welcome-page__advantages-block">
                <div className="container p-0">
                    <div className="welcome-page__advantages">
                        <h2 className="visually-hidden welcome-page__header welcome-page__title-h3 welcome-page__advantages-title">
                            Отправь фото в 3 клика
                        </h2>
                        <ul className="welcome-page__advantages-list">
                            <AdvantagesListItem
                                titleText='Достань телефон'
                                contentText='Открой приложение Quick Shot'
                                image = {advantages1}
                                number = "1"
                            />

                            <AdvantagesListItem
                                titleText='Сфотографируй'
                                contentText='Найди правильный ракурс, а приложение автоматически сфокусируется на самом важном, тебе останется только нажать на кнопу'
                                image = {advantages2}
                                number = "2"
                            />

                            <AdvantagesListItem
                                titleText='Готово'
                                contentText='Все, твоя фотография готова, сохранена и загружена в автоматическом режиме в облако'
                                image = {advantages3}
                                number = "3"
                            />
                        </ul>
                        <div className="welcome-page__advantages-man">
                            <img src={man} alt="man" />
                        </div>
                    </div>
                </div>
                <div className="welcome-page__faceBlock"></div>
            </div>
            
            {/* Advantages end */}
            
            {/* FAQ start */}

            <div className="container-fluid welcome-page__section">
                <div className="container p-0">
                    <div className="welcome-page__faq">
                        <h2 className="welcome-page__header welcome-page__title-h2 welcome-page__faq-title">
                            Часто задаваемые вопросы
                        </h2>
                        <div className="container p-0 welcome-page__faq-topics-container">

                            {questionMas.map((item, i) => {
                                return ( 
                                    <FaqListItem
                                        refItem = {(element) => itemEls.current[i] = element}
                                        key = {i}
                                        question = {item.question} 
                                        answer = {item.answer}
                                    />
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>

            {/* FAQ end */}

            {/* Get started start */}

            <div className="container-fluid welcome-page__section welcome-page__section_pt0">
                <div className="container p-0">
                    <div className="welcome-page__getstarted">
                        <h2 className="welcome-page__header welcome-page__title-h2 welcome-page__getstarted-title">
                            Заинтересовало приложение?
                        </h2>
                        <p className="welcome-page__description-text welcome-page__getstarted-text">
                            Свяжитесь с нами и мы поможем Вам его установить.
                        </p>
                        <div onClick={openModalContacts} className="welcome-page__btns welcome-page__getstarted-link">
                            Контакты
                        </div>
                        <div className="welcome-page__getstarted-roc">
                            <img src={roc} alt="roc" />
                        </div>
                        <div className="welcome-page__getstarted-cloud">
                            <img src={cloud} alt="cloud" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Get started end */}

            <div className="container-fluid">
                <div className="container p-0">
                    <div className="welcome-page__footer">
                        <SVG src={logo}/>
                        <span className="welcome-page__footer-text">© Quick Shot {new Date().getFullYear()} | Автоматическая отправка фото</span>
                    </div>
                </div>  
            </div>

            <ModalWindowContacts/>

        </Fragment>
    )
});
