import React, {Fragment, useRef} from "react";
import "./modalWindowChangeQrCode.scss";
// import styles from './modalAvatarEditDesktop.scss';


import SVG from 'react-inlinesvg';
import times from '../../media/modal/times.svg';
import check from '../../media/modal/check.svg';

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';
import { modalSetModalChangeQrCodeShowBool } from "../../../store/actions/modal/modalActions";
import { changeLinkQrCodeApi } from "../../../services/panelRequestsService";
import { panelSetlinkQrCode } from "../../../store/actions/panel/panelAction";


export const ModalWindowChangeQrCode = React.memo( function ModalWindowChangeQrCode(props){
    const dispatch = useDispatch();

    const modalChangeQrCodeShowBool = useSelector((state) => state.modal.modalChangeQrCodeShowBool, shallowEqual);

    const linkQrCode = useSelector((state) => state.panel.linkQrCode, shallowEqual);

    const inputUrlRef = useRef(null);

    function closeModalChangeQrCode() {
        dispatch(modalSetModalChangeQrCodeShowBool(false))
    }

    function handlerChangeLinkQrCode(event){
        event.preventDefault();
        let urlValue = inputUrlRef.current.value;
            changeLinkQrCodeApi(urlValue)
                .then(response => {
                    console.log(response.data);
                    dispatch(panelSetlinkQrCode(response.data.url))
                    closeModalChangeQrCode();
                })
                .catch(e => {
                    console.log(e);
                });
        
    }

    
    return(
        <Fragment>
            {console.log("reload_modalWindowChangeQrCode")}
            <CSSTransition
                in={modalChangeQrCodeShowBool}
                classNames="modalWindowChangeQrCodeAnim"
                timeout={props.cssTransitionTimeout}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`modalWindowChangeQrCodeBG ${state}`}>
                        <div onClick={closeModalChangeQrCode} className="modalWindowChangeQrCodeBG__close">
                            <SVG className="modalWindowChangeQrCodeBG__close__button" src={times}/>
                        </div>
                        <div className="modalWindowChangeQrCode">

                                <span className="modalWindowChangeQrCode__header-name">Новая ссылка</span>

                                <div className="modalWindowChangeQrCode__add-block ">
                                    <form onSubmit={handlerChangeLinkQrCode}>
                                        <div className="modalWindowChangeQrCode__input-block" data-error="В ссылке ошибка">
                                            <input
                                                ref={inputUrlRef}
                                                className="modalWindowChangeQrCode__input"
                                                type="url"
                                                placeholder="Введите ссылку"
                                                defaultValue={linkQrCode}
                                            />
                                        </div>

                                        <div className="modalWindowChangeQrCode__button-block">
                                            <div onClick={closeModalChangeQrCode} className="modalWindowChangeQrCode__cancel-button">Отмена</div>
                                            <button type = "submit" className="modalWindowChangeQrCode__create-button">Обновить</button>
                                        </div>
                                    </form>
                                </div>
                                
                                <div className="modalWindowChangeQrCode__success hide">
                                    <SVG className="modalWindowChangeQrCode__svg" src={check}/>
                                    <div className="modalWindowChangeQrCode__text-success">Ссылка успешна заменена</div>
                                    <div onClick={closeModalChangeQrCode} className="modalWindowChangeQrCode__close-button">Закрыть</div>
                                </div>


                        </div>
                    </div>
                }

            </CSSTransition>
        </Fragment>
    )
});