import { MODAL_SET_ANY_WINDOW_IS_OPEN, MODAL_SET_MODAL_ACCESS_DATA_SHOW_BOOL, MODAL_SET_MODAL_ACCURATELY_REMOVE_SHOW_BOOL, MODAL_SET_MODAL_ADD_DEALERS_SHOW_BOOL, MODAL_SET_MODAL_ADD_USER_SHOW_BOOL, MODAL_SET_MODAL_CHANGE_QR_CODE_SHOW_BOOL, MODAL_SET_MODAL_CONTACTS_SHOW_BOOL, MODAL_SET_MODAL_DEALERS_SHOW_BOOL, MODAL_SET_MODAL_USER_PHOTO_SHOW_BOOL, MODAL_SET_MODAL_WHY_DONT_I_HAVE_PHOTOS_SHOW_BOOL, MODAL_SET_TYPE_DELETE, USER_FOR_MODAL_WINDOW } from "./modalTypes"
import update from 'immutability-helper';

export const modalSetUserForModalWindow = (userForModalWindow) =>{
    return{
        type: USER_FOR_MODAL_WINDOW,
        payload:{
            userForModalWindow
        }
    }
}

export const modalSetModalAddUserShowBool = (modalAddUserShowBool) =>{
    return{
        type: MODAL_SET_MODAL_ADD_USER_SHOW_BOOL,
        payload:{
            modalAddUserShowBool
        }
    }
}

export const modalSetModalAccessDataShowBool = (modalAccessDataShowBool) =>{
    return{
        type: MODAL_SET_MODAL_ACCESS_DATA_SHOW_BOOL,
        payload:{
            modalAccessDataShowBool
        }
    }
}

export const modalSetModalAccuratelyRemoveShowBool = (modalAccuratelyRemoveShowBool) =>{
    return{
        type: MODAL_SET_MODAL_ACCURATELY_REMOVE_SHOW_BOOL,
        payload:{
            modalAccuratelyRemoveShowBool
        }
    }
}

export const modalSetTypeDelete = (typeDelete) =>{
    return{
        type: MODAL_SET_TYPE_DELETE,
        payload:{
            typeDelete
        }
    }
}

export const modalSetModalAddDealerShowBool = (modalAddDealerShowBool) =>{
    return{
        type: MODAL_SET_MODAL_ADD_DEALERS_SHOW_BOOL,
        payload:{
            modalAddDealerShowBool
        }
    }
}

export const modalSetModalChangeQrCodeShowBool = (modalChangeQrCodeShowBool) =>{
    return{
        type: MODAL_SET_MODAL_CHANGE_QR_CODE_SHOW_BOOL,
        payload:{
            modalChangeQrCodeShowBool
        }
    }
}


export const modalSetModalDealersShowBool = (modalDealersShowBool) =>{
    return{
        type: MODAL_SET_MODAL_DEALERS_SHOW_BOOL,
        payload:{
            modalDealersShowBool
        }
    }
}

export const modalSetModalUserPhotoShowBool = (modalUserPhotoShowBool) =>{
    return{
        type: MODAL_SET_MODAL_USER_PHOTO_SHOW_BOOL,
        payload:{
            modalUserPhotoShowBool
        }
    }
}

export const modalSetModalContactsShowBool = (modalContactsShowBool) =>{
    return{
        type: MODAL_SET_MODAL_CONTACTS_SHOW_BOOL,
        payload:{
            modalContactsShowBool
        }
    }
}

export const modalSetModalWhyDontIHavePhotosShowBool = (modalWhyDontIHavePhotosShowBool) =>{
    return{
        type: MODAL_SET_MODAL_WHY_DONT_I_HAVE_PHOTOS_SHOW_BOOL,
        payload:{
            modalWhyDontIHavePhotosShowBool
        }
    }
}

export const modalSetAnyWindowIsOpen = (anyWindowIsOpen) =>{
    return{
        type: MODAL_SET_ANY_WINDOW_IS_OPEN,
        payload:{
            anyWindowIsOpen
        }
    }
}



export const modalSetUserForModalWindowWithPassword = (password) =>{
    return function (dispatch, getState){
        var newUserForModalWindow = getState().modal.userForModalWindow;
        const newData = update(newUserForModalWindow, {
            password:{$set: password}
        });
        dispatch(modalSetUserForModalWindow(newData));
    }
}


export const modalSetCloseAllModalWindow = () =>{
    return function (dispatch, getState){
        dispatch(modalSetModalAddUserShowBool(false));
        dispatch(modalSetModalAccessDataShowBool(false));
        dispatch(modalSetModalAccuratelyRemoveShowBool(false));
        dispatch(modalSetModalAddDealerShowBool(false));
        dispatch(modalSetModalChangeQrCodeShowBool(false));
        dispatch(modalSetModalDealersShowBool(false));
        dispatch(modalSetModalUserPhotoShowBool(false));
        dispatch(modalSetModalContactsShowBool(false));
        dispatch(modalSetModalWhyDontIHavePhotosShowBool(false));
    } 
}