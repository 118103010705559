import React, { Fragment, useEffect } from "react";
import "./panelUser.scss";

import SVG from 'react-inlinesvg';
import syncAlt from "../../media/panel/sync-alt.svg";

import { FilePhoto } from "../../general/filePhoto/filePhoto";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { panelAsyncSetSetOfPhotos, panelSetCountPhotoFromServerInSetOfPhotos, panelSetSetOfPhotos } from "../../../store/actions/panel/panelAction";
import { FilePhotoByDay } from "../../general/filePhotoByDay/filePhotoByDay";
import { areTheTwoDaysDifferent } from "../../general/functions";
import { Pacifier } from "../../general/pacifier/pacifier";
import { FilePhotoGeneralized } from "../../general/filePhotoGeneralized/filePhotoGeneralized";
import { propTypes } from "qrcode.react";
// import { SocketComponent } from "../../general/socketExt/SocketComponent";

export const PanelUser = React.memo( function PanelUser(props){
    const dispatch = useDispatch();
    const setOfPhotos = useSelector((state) => state.panel.setOfPhotos, shallowEqual);
    const spinnerSmall = useSelector((state) => state.panel.spinnerSmall, shallowEqual);
    
    function handleGetSetOfPhotos(){
        dispatch(panelAsyncSetSetOfPhotos(props.authUser.id));
    }

    useEffect(() => {
        if (props.authUser){
            handleGetSetOfPhotos();
        }
    }, [props.authUser]);


    return(
        <Fragment>
            {console.log('rerender panelUser')}
            <div className="panelUser">

                
                {/* <SocketComponent name = {props.authUser.email}/> */} 
                {/* {setOfPhotos.photoCounter > 0 &&
                    <FilePhotoByDay photos = {setOfPhotos.photos}/>
                } */}
                <div className="panelUser__header">
                    <div onClick={handleGetSetOfPhotos} className="panelUser__button-update-photos" style={{pointerEvents: spinnerSmall? "none":""}}>
                        <SVG src={syncAlt} className={spinnerSmall ? "panelUser__show-spinner" : ""} />
                        <span>Обновить список</span>
                    </div>
                    <div className="panelUser__text-number-of-photos">
                        <span>Количество фото:<span className="panelUser__number">{setOfPhotos.photoConfirmCounter}</span></span>
                    </div>
                    {setOfPhotos && setOfPhotos.photoConfirmCounter < setOfPhotos.countPhotoFromServer &&
                        <div onClick={handleGetSetOfPhotos} className="panelUser__button-new-photo">
                            <span>Показать</span>
                            <div className="panelUser__new-photo">{setOfPhotos.countPhotoFromServer - setOfPhotos.photoConfirmCounter}</div>
                            <span>новые фотографии</span>
                        </div>
                    }
                </div>
                
                <FilePhotoGeneralized/>
                        

                {/* {(setOfPhotos.photos || []).map((photo, i) => {
                    return  (   
                                <FilePhoto key = {photo.id} photo = {photo}/>
                            )
                    })
                } */}

                {/* <div className="panelUser__title">24 Mar 2022</div>
                <FilePhoto />
                <FilePhoto />
                <FilePhoto />
                <FilePhoto />
                <FilePhoto />


                <div className="panelUser__title">23 Mar 2022</div>
                <FilePhoto />
                <FilePhoto />
                <FilePhoto />
                <FilePhoto /> */}

                
                

            </div>
        </Fragment>
    )
});