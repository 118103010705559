import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { panelAsyncSetCardUsersArray, panelSetDealerSelect } from "../../../store/actions/panel/panelAction";

export const FilterDealer = React.memo( function FilterDealer(props){
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.app.authUser, shallowEqual);
    const cardDealersArray = useSelector((state) => state.panel.cardDealersArray, shallowEqual);
    const dealerSelect = useSelector((state) => state.panel.dealerSelect, shallowEqual);

    const [noneClientCount, setNoneClientCount] = useState(0);


    function handleSetDealerSelect(value){
        console.log('value', value)

        dispatch(panelSetDealerSelect(value));
        dispatch(panelAsyncSetCardUsersArray());
    }

    useEffect(() => {
        var summaClients = 0
        if(cardDealersArray.length > 0){
            for (let index = 0; index < cardDealersArray.length; index++) {
                summaClients += cardDealersArray[index].clientCounter
            }
        }
        summaClients += authUser.clientCounter;
        console.log(summaClients, 'summaClients');
        setNoneClientCount(authUser.allClients - summaClients);
    }, [cardDealersArray]);


    return(
        <Fragment>
            {console.log('rerender filterDealer')}

            <div className="panelAdmin__title">Дилеры</div>
            <form className="panelAdmin__form">

                <input hidden id="dealersAll" type="radio" name="dealersRadio" value="all" onChange={(e) => handleSetDealerSelect(e.currentTarget.value)} checked = {dealerSelect == "all"}/>
                <label htmlFor="dealersAll" className="panelAdmin__radioButton">Все пользователи:<span>{authUser.allClients}</span></label>

                <input hidden id={authUser.id} type="radio" name="dealersRadio" value={authUser.email} onChange={(e) => handleSetDealerSelect(e.currentTarget.value)} checked = {dealerSelect == authUser.email}/>
                <label htmlFor={authUser.id} className="panelAdmin__radioButton">{authUser.email}:<span>{authUser.clientCounter}</span></label>

                {(cardDealersArray || []).map((dealer, i) => {
                    return  (   
                            <Fragment key = {dealer.id}>
                                <input hidden id={dealer.id} type="radio" name="dealersRadio" value={dealer.email} onChange={(e) => handleSetDealerSelect(e.currentTarget.value)} checked = {dealerSelect == dealer.email}/>
                                <label htmlFor={dealer.id} className="panelAdmin__radioButton">{dealer.email}:<span>{dealer.clientCounter}</span></label>
                            </Fragment>
                            )
                    })}

                <input hidden id="dealerNone" type="radio" name="dealersRadio" value="None" onChange={(e) => handleSetDealerSelect(e.currentTarget.value)} checked = {dealerSelect == "None"}/>
                <label htmlFor="dealerNone" className="panelAdmin__radioButton">None:<span>{noneClientCount}</span></label>
            </form>

        </Fragment>
    )
});