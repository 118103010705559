import React, { Fragment, useEffect, useRef, useState } from "react";
import "./header.scss";
import styles from "./header.scss";
import { Link, useHistory } from "react-router-dom";

import SVG from 'react-inlinesvg';
import logo from "../media/header/logo_2.svg";
import signOutAlt from "../media/header/sign-out-alt.svg";
import trash from "../media/header/trash.svg";

import logoWhite from "../media/header/logo_1.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { appAsyncSetAuthUser, appSetAuthUser } from "../../store/actions/app/appActions";
import { auth, logOutApi } from "../../services/userRequestsService";
import { modalSetModalAccuratelyRemoveShowBool, modalSetTypeDelete, modalSetUserForModalWindow } from "../../store/actions/modal/modalActions";



export const Header = React.memo( function Header(props){
    const dispatch = useDispatch();

    const authUser = useSelector((state) => state.app.authUser, shallowEqual);


    const loginButtonRef = useRef(null);
    const logoutButtonRef = useRef(null);
    const removeAllPhotosRef = useRef(null);


    let history = useHistory();


    useEffect(() => {
        dispatch(appAsyncSetAuthUser())
            .then(response => {
                if (response.data.token === "empty" && props.pathname == "/panel"){
                    history.push("/");
                }
            });
      },[props.pathname]);

    function exit(){
        localStorage.setItem('token', "");
        dispatch(appSetAuthUser(""));
        history.push("/");
    }

    function logOut(){
        console.log("log_out");
        exit();
    }

    useEffect(() => {
        const handleInvalidToken = e => {
            console.log("yay tut");
            if (e.key === 'token' && (e.oldValue !== e.newValue)){
                window.location.reload(false);
            }
        }
        window.addEventListener('storage', handleInvalidToken)
        return function cleanup() {
            window.removeEventListener('storage', handleInvalidToken)
        }
    }, [])

    function openModalAccuratelyRemove(typeDelete) {
        dispatch(modalSetUserForModalWindow(authUser));
        dispatch(modalSetTypeDelete(typeDelete));
        dispatch(modalSetModalAccuratelyRemoveShowBool(true));
    }
        

    return(
        <Fragment>
            {console.log('rerender Header')}
            <div className="container-fluid headerFluid">
                <div className="container p-0 header">

                    <div className="header__buttonBlock row">

                        <div className="header__logoBlock col-sm p-0">
                            <Link to="/" type="button" className="header__logo"><SVG src={props.pathname === "/panel" ? logoWhite : logo} /></Link>
                        </div>
                        
                        
                        {props.pathname != "/signin" &&
                            <div className="header__button col-sm p-0 d-flex justify-content-end">
                                {authUser && props.pathname != "/panel" &&
                                    <Link ref={loginButtonRef} to="/panel" type="button" className="header__authUserName long-and-truncated">{authUser.email}</Link>
                                }
                                {authUser == "" &&
                                    <Link ref={loginButtonRef} to="/signin" type="button" className="header__loginButton">Войти</Link>
                                }
                                {authUser.role == "Client" && props.pathname == "/panel" &&
                                    <div onClick={() => openModalAccuratelyRemove("Photo")} ref={removeAllPhotosRef} className="header__removeAllPhotos long-and-truncated">
                                        <SVG src={trash}/>
                                        <div className="long-and-truncated">Удалить все фото</div>
                                    </div>
                                }
                                {authUser.role == "Admin" && props.pathname == "/panel" &&
                                    <div onClick={() => openModalAccuratelyRemove("PhotoOfAll")} ref={removeAllPhotosRef} className="header__removeAllPhotos long-and-truncated">
                                        <SVG src={trash}/>
                                        <div className="long-and-truncated">Удалить все фото у всех</div>
                                    </div>
                                }

                                {authUser &&
                                    <div ref={logoutButtonRef} className="header__logoutButton" onClick={logOut}>
                                        <SVG src={signOutAlt}/>
                                        <div className="long-and-truncated">Выйти</div>
                                    </div>
                                }
                            </div>
                        }
                        
                        
                        

                    </div>

                    

                </div>
            </div>

        </Fragment>
    )
});