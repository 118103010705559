import React, {Fragment, useEffect, useState} from "react";
import "./modalWindowAccessData.scss";
// import styles from './modalAvatarEditDesktop.scss';


import SVG from 'react-inlinesvg';
import times from '../../media/modal/times.svg';

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';
import { modalSetModalAccessDataShowBool, modalSetModalAccuratelyRemoveShowBool, modalSetTypeDelete, modalSetUserForModalWindowWithPassword } from "../../../store/actions/modal/modalActions";
import { getUserEmailPasswordApi } from "../../../services/panelRequestsService";


export const ModalWindowAccessData = React.memo( function ModalWindowAccessData(props){
    const dispatch = useDispatch();

    const authUser = useSelector((state) => state.app.authUser, shallowEqual);
    const modalAccessDataShowBool = useSelector((state) => state.modal.modalAccessDataShowBool, shallowEqual);
    const userForModalWindow = useSelector((state) => state.modal.userForModalWindow, shallowEqual);




    
    function closeModalAccessData() {
        dispatch(modalSetModalAccessDataShowBool(false))
    }

    function openModalAccuratelyRemove() {
        dispatch(modalSetTypeDelete("ChangePassword"));
        dispatch(modalSetModalAccuratelyRemoveShowBool(true));
    }

    function handlerGetEmailAndPassword() {
        getUserEmailPasswordApi(userForModalWindow.id)
            .then(response => {
                console.log(response.data);
                dispatch(modalSetUserForModalWindowWithPassword(response.data.password));
            })
            .catch(e => {
                console.log(e);
            });
    }

    
    useEffect(() => {
        if (modalAccessDataShowBool && userForModalWindow){
            handlerGetEmailAndPassword();
        }
    }, [modalAccessDataShowBool, userForModalWindow.id]);

    
    return(
        <Fragment>
            {console.log("reload_modalWindowAccessData")}
            <CSSTransition
                in={modalAccessDataShowBool}
                classNames="modalWindowAccessDataAnim"
                timeout={props.cssTransitionTimeout}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`modalWindowAccessDataBG ${state}`}>
                        <div onClick={closeModalAccessData} className="modalWindowAccessDataBG__close">
                            <SVG className="modalWindowAccessDataBG__close__button" src={times}/>
                        </div>
                        <div className="modalWindowAccessData">

                                <span className="modalWindowAccessData__header-name">Email и пароль</span>

                                <div className="modalWindowAccessData__email-password-block">
                                    <div className="modalWindowAccessData__email">{userForModalWindow.email}</div>
                                    <div className="modalWindowAccessData__password">{userForModalWindow.password}</div>
                                </div>

                                {authUser.role == "Dealer" ?
                                    ""
                                :
                                    <div onClick={openModalAccuratelyRemove} className="modalWindowAccessData__change-button">Сменить пароль</div>
                                }

                                

                                <div onClick={closeModalAccessData} className="modalWindowAccessData__close-button">Закрыть</div>


                        </div>
                    </div>
                }

            </CSSTransition>
        </Fragment>
    )
});