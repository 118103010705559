import React, {Fragment} from "react";
import "./modalWindowDealers.scss";
// import styles from './modalAvatarEditDesktop.scss';


import SVG from 'react-inlinesvg';
import times from '../../media/modal/times.svg';

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';
import { modalSetModalAddDealerShowBool, modalSetModalDealersShowBool } from "../../../store/actions/modal/modalActions";
import { CardDealer } from "../../general/cardDealer/cardDealer";
// import { panelAsyncSetCardDealersArray } from "../../../store/actions/panel/panelAction";


export const ModalWindowDealers = React.memo( function ModalWindowDealers(props){
    const dispatch = useDispatch();

    const modalDealersShowBool = useSelector((state) => state.modal.modalDealersShowBool, shallowEqual);
    const cardDealersArray = useSelector((state) => state.panel.cardDealersArray, shallowEqual);

    function closeModalDealers() {
        dispatch(modalSetModalDealersShowBool(false))
    }

    function openModalAddDealer() {
        dispatch(modalSetModalAddDealerShowBool(true))
    }


    
    return(
        <Fragment>
            {console.log("reload_modalWindowDealers")}
            <CSSTransition
                in={modalDealersShowBool}
                classNames="modalWindowDealersAnim"
                timeout={props.cssTransitionTimeout}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`modalWindowDealersBG ${state}`}>
                        <div onClick={closeModalDealers} className="modalWindowDealersBG__close">
                            <SVG className="modalWindowDealersBG__close__button" src={times}/>
                        </div>
                        <div className="modalWindowDealers">

                                <span className="modalWindowDealers__header-name">Дилеры</span>

                                <div className="modalWindowDealers__count-dealer-block">
                                    <span className="modalWindowDealers__count-text">Количество:</span>
                                    <span className="modalWindowDealers__count-number">{props.authUser.dealerCounter}</span>
                                </div>

                                <div className="modalWindowDealers__dealer-block">
                                    {(cardDealersArray || []).map((dealer, i) => {
                                    return  (   
                                                <CardDealer key = {dealer.id} dealer = {dealer}/>
                                            )
                                    })}
                                </div>

                                <div className="modalWindowDealers__button-block">
                                    <div onClick={openModalAddDealer} className="modalWindowDealers__add-button">Добавить диллера</div>
                                </div>

                                <div className="modalWindowDealers__button-block">
                                    <div onClick={closeModalDealers} className="modalWindowDealers__close-button">Закрыть</div>
                                </div>


                        </div>
                    </div>
                }

            </CSSTransition>
        </Fragment>
    )
});