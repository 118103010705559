import React, { Fragment, useRef } from "react";
import './cardsByDay.scss';

import { useDispatch } from "react-redux";
import { CardUser } from "../cardUser/cardUser";
import { emailHider, toDateDay, toDateTime } from "../functions";

export const CardsByDay = React.memo( function CardsByDay(props){
    
   
    return(
        <Fragment>
            {console.log('rerender cardsByDay')}
            <div className="cards-by-day">
                <div className="cards-by-day__title">{props.cards[0]? toDateDay(props.cards[0].created): ""} ({props.cards.length} чел.)</div>
                <div className="cards-by-day__cardUser">
                    {(props.cards || []).map((card, i) => {
                        return  (   
                                    <CardUser key = {card.id}  card = {card}/>
                                )
                        })}
                </div>

            </div>
        </Fragment>
    )
});