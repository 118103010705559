import React, { Fragment, useEffect, useRef, useState } from "react";
import './filePhotoGeneralized.scss';

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { areTheTwoDaysDifferent, emailHider, toDateDay, toDateTime } from "../functions";
import { FilePhoto } from "../filePhoto/filePhoto";
import { Pacifier } from "../pacifier/pacifier";
import { FilePhotoByDay } from "../filePhotoByDay/filePhotoByDay";
import { panelAsyncSetSetOfPhotos } from "../../../store/actions/panel/panelAction";
// import { SocketComponent } from "../socketExt/SocketComponent";

export const FilePhotoGeneralized = React.memo( function FilePhotoGeneralized(props){
    const dispatch = useDispatch();
    
    const userForModalWindow = useSelector((state) => state.modal.userForModalWindow, shallowEqual);

    const setOfPhotos = useSelector((state) => state.panel.setOfPhotos, shallowEqual);
    const buttonShowAll = useSelector((state) => state.panel.buttonShowAll, shallowEqual);

    const authUser = useSelector((state) => state.app.authUser, shallowEqual);

    const [filePhotoByDayArray, setFilePhotoByDayArray] = useState([]);

    function handleGetSetOfPhotosShowAll(){
        if (authUser.role === "Client"){
            dispatch(panelAsyncSetSetOfPhotos(authUser.id, true, true));
        }
        else{
            dispatch(panelAsyncSetSetOfPhotos(userForModalWindow.id, true, true));
        }
        
    }
    


    useEffect(() => {
        if (setOfPhotos.photoCounter > 0){
            let tmpFirstArray = []
            let tmpSecondArray = []
            for (let i = 0; i < setOfPhotos.photos.length; i++) {
                if (!setOfPhotos.photos[i].confirmed && (authUser.role == "Client" || authUser.role == "Dealer") ){
                    continue;
                }
                if (i > 0){
                    if (areTheTwoDaysDifferent(setOfPhotos.photos[i-1].created, setOfPhotos.photos[i].created)){
                        tmpFirstArray.push(tmpSecondArray)
                        tmpSecondArray = []
                    }
                }
                tmpSecondArray.push(setOfPhotos.photos[i]);
            }
            tmpFirstArray.push(tmpSecondArray)

            setFilePhotoByDayArray(tmpFirstArray);
        }
        else{
            setFilePhotoByDayArray([]);
        }
    },[setOfPhotos.photos]);
    
   
    return(
        <Fragment>
            {console.log('rerender filePhotoGeneralized')}
            <div className="filePhotoGeneralized">
            
                {setOfPhotos && filePhotoByDayArray.length === 0 ?
                            <Pacifier/>
                        :
                        <>

                            {(filePhotoByDayArray || []).map((photos, i) => {
                            return  (   
                                        <FilePhotoByDay key = {i} photos = {photos}/>
                                    )
                            })}
                            {buttonShowAll &&
                                <div onClick={handleGetSetOfPhotosShowAll} className="filePhotoGeneralized__button-upload-photo">Показать остальные</div>
                            }
                        </>

                }

            </div>
        </Fragment>
    )
});