import { MODAL_SET_ANY_WINDOW_IS_OPEN, MODAL_SET_MODAL_ACCESS_DATA_SHOW_BOOL, MODAL_SET_MODAL_ACCURATELY_REMOVE_SHOW_BOOL, MODAL_SET_MODAL_ADD_DEALERS_SHOW_BOOL, MODAL_SET_MODAL_ADD_USER_SHOW_BOOL, MODAL_SET_MODAL_CHANGE_QR_CODE_SHOW_BOOL, MODAL_SET_MODAL_CONTACTS_SHOW_BOOL, MODAL_SET_MODAL_DEALERS_SHOW_BOOL, MODAL_SET_MODAL_USER_PHOTO_SHOW_BOOL, MODAL_SET_MODAL_WHY_DONT_I_HAVE_PHOTOS_SHOW_BOOL, MODAL_SET_TYPE_DELETE, USER_FOR_MODAL_WINDOW } from "../../actions/modal/modalTypes"


const initialState = {
    userForModalWindow: "",
    modalAddUserShowBool: false,
    modalAccessDataShowBool: false,
    modalAccuratelyRemoveShowBool: false,
    typeDelete: "",
    modalAddDealerShowBool: false,
    modalChangeQrCodeShowBool: false,
    modalDealersShowBool: false,
    modalUserPhotoShowBool: false,
    modalContactsShowBool: false,
    modalWhyDontIHavePhotosShowBool: false,
    anyWindowIsOpen: false,
    cssTransitionTimeout: 320
};

export const modalReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case USER_FOR_MODAL_WINDOW:
            return {...state, userForModalWindow: payload.userForModalWindow };

        case MODAL_SET_MODAL_ADD_USER_SHOW_BOOL:
            return {...state, modalAddUserShowBool: payload.modalAddUserShowBool };

        case MODAL_SET_MODAL_ACCESS_DATA_SHOW_BOOL:
            return {...state, modalAccessDataShowBool: payload.modalAccessDataShowBool };

        case MODAL_SET_MODAL_ACCURATELY_REMOVE_SHOW_BOOL:
            return {...state, modalAccuratelyRemoveShowBool: payload.modalAccuratelyRemoveShowBool };

        case MODAL_SET_TYPE_DELETE:
            return {...state, typeDelete: payload.typeDelete };

        case MODAL_SET_MODAL_ADD_DEALERS_SHOW_BOOL:
            return {...state, modalAddDealerShowBool: payload.modalAddDealerShowBool };

        case MODAL_SET_MODAL_CHANGE_QR_CODE_SHOW_BOOL:
            return {...state, modalChangeQrCodeShowBool: payload.modalChangeQrCodeShowBool };
 
        case MODAL_SET_MODAL_DEALERS_SHOW_BOOL:
            return {...state, modalDealersShowBool: payload.modalDealersShowBool };

        case MODAL_SET_MODAL_USER_PHOTO_SHOW_BOOL:
            return {...state, modalUserPhotoShowBool: payload.modalUserPhotoShowBool };

        case MODAL_SET_MODAL_CONTACTS_SHOW_BOOL:
            return {...state, modalContactsShowBool: payload.modalContactsShowBool };

        case MODAL_SET_MODAL_WHY_DONT_I_HAVE_PHOTOS_SHOW_BOOL:
            return {...state, modalWhyDontIHavePhotosShowBool: payload.modalWhyDontIHavePhotosShowBool };

        case MODAL_SET_ANY_WINDOW_IS_OPEN:
            return {...state, anyWindowIsOpen: payload.anyWindowIsOpen };

        default:
            return state;
    }
};