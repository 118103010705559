import React, { Fragment } from "react";
import "./pacifier.scss";

import SVG from 'react-inlinesvg';
import users from "../../media/panel/meh-rolling-eyes.svg";
import { useDispatch } from "react-redux";
import { modalSetModalWhyDontIHavePhotosShowBool } from "../../../store/actions/modal/modalActions";

export const Pacifier = React.memo( function Pacifier(props){
    const dispatch = useDispatch();

    function openModalWhyDontIHavePhotos() {
        dispatch(modalSetModalWhyDontIHavePhotosShowBool(true));
    }

    return(
        <Fragment>
            {console.log('rerender pacifier')}

            <div className="pacifier">

                <div className="pacifier__svg-block">
                    <SVG src={users}/>
                </div>

                <div className="pacifier__text-block">
                    <span>Пусто</span>
                </div>

                <div className="pacifier__question-block">
                    <span onClick={openModalWhyDontIHavePhotos}>Почему у меня нет фотографий?</span>
                </div>

            </div>

        </Fragment>
    )
});