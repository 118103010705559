import React, {Fragment, useEffect, useState} from "react";
import "./modalWindowWhyDontIHavePhotos.scss";
// import styles from './modalAvatarEditDesktop.scss';


import SVG from 'react-inlinesvg';
import times from '../../media/modal/times.svg';

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';
import { modalSetModalWhyDontIHavePhotosShowBool } from "../../../store/actions/modal/modalActions";


export const ModalWindowWhyDontIHavePhotos = React.memo( function ModalWindowWhyDontIHavePhotos(props){
    const dispatch = useDispatch();

    const modalWhyDontIHavePhotosShowBool = useSelector((state) => state.modal.modalWhyDontIHavePhotosShowBool, shallowEqual);


    function closeModalWindowWhyDontIHavePhotos() {
        dispatch(modalSetModalWhyDontIHavePhotosShowBool(false));
    }

    
    return(
        <Fragment>
            {console.log("reload_modalWindowWhyDontIHavePhotos")}
            <CSSTransition
                in={modalWhyDontIHavePhotosShowBool}
                classNames="modalWindowWhyDontIHavePhotosAnim"
                timeout={props.cssTransitionTimeout}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`modalWindowWhyDontIHavePhotosBG ${state}`}>
                        <div onClick={closeModalWindowWhyDontIHavePhotos} className="modalWindowWhyDontIHavePhotosBG__close">
                            <SVG className="modalWindowWhyDontIHavePhotosBG__close__button" src={times}/>
                        </div>
                        <div className="modalWindowWhyDontIHavePhotos">

                                <span className="modalWindowWhyDontIHavePhotos__header-name">Почему у меня нет фотографий?</span>

                                <div className="modalWindowWhyDontIHavePhotos__text-block">
                                    <span>Это может быть по причине:</span>
                                    <span className="modalWindowWhyDontIHavePhotos__text-answer">1. Вы просто еще не сделали фотографии</span>
                                    <span className="modalWindowWhyDontIHavePhotos__text-answer">2. Вы удалили свои фотографии</span>
                                    <span className="modalWindowWhyDontIHavePhotos__text-answer">3. Ваши фотографии удалены в плановом режиме</span>
                                </div>
                              

                                <div onClick={closeModalWindowWhyDontIHavePhotos} className="modalWindowWhyDontIHavePhotos__close-button">Закрыть</div>


                        </div>
                    </div>
                }

            </CSSTransition>
        </Fragment>
    )
});