import API from "./api";

export async function getClientsApi(searchWord = "", dealerSelect = "all", availabilityPhoto = "all") {
    return await API.post('/api/panel/getClientsApi', { searchWord: searchWord, dealerSelect: dealerSelect, availabilityPhoto: availabilityPhoto});
}

export async function getDealersApi() {
    return await API.post('/api/panel/getDealersApi');
}

export async function getSetOfPhotosApi(userId, showAll) {
    return await API.post('/api/panel/getSetOfPhotosApi', {userId: userId, showAll: showAll});
}

export async function addClientApi(email) {
    return await API.post('/api/auth/addClientApi', { email: email});
}

export async function addDealerApi(email) {
    return await API.post('/api/auth/addDealerApi', { email: email});
}

export async function getUserEmailPasswordApi(userId) {
    return await API.post('/api/panel/getUserEmailPasswordApi', { userId: userId});
}

export async function deleteClientApi(userId) {
    return await API.post('/api/auth/deleteClientApi', { userId: userId});
}

export async function deleteDealerApi(userId) {
    return await API.post('/api/auth/deleteDealerApi', { userId: userId});
}

export async function changeUserPasswordApi(userId) {
    return await API.post('/api/auth/changeUserPasswordApi', { userId: userId});
}

export async function changeLinkQrCodeApi(url) {
    return await API.post('/api/panel/changeLinkQrCodeApi', { url: url});
}

export async function getLinkQrCodeApi() {
    return await API.get('/api/panel/getLinkQrCodeApi');
}

export async function deleteAllPhotoOfUserApi(userId) {
    return await API.post('/api/panel/deleteAllPhotoOfUserApi', { userId: userId});
}

export async function deleteAllPhotoFromAdminApi() {
    return await API.post('/api/panel/deleteAllPhotoFromAdminApi');
}

export async function getImageUrlApi(setOfPhotosId, photoId) {
    return await API.post('/api/panel/getImageUrlApi', { setOfPhotosId:setOfPhotosId, photoId:photoId});
}




