import React, { Fragment } from "react";
import "./cardDealer.scss";

import SVG from 'react-inlinesvg';
import trash from "../../media/panel/trash.svg";
import eye from "../../media/panel/eye.svg";
import { modalSetModalAccessDataShowBool, modalSetModalAccuratelyRemoveShowBool, modalSetUserForModalWindow, modalSetTypeDelete} from "../../../store/actions/modal/modalActions";
import { useDispatch } from "react-redux";
import { toDateTime } from "../functions";

export const CardDealer = React.memo( function CardDealer(props){
    const dispatch = useDispatch();

    function openModalAccessData() {
        dispatch(modalSetUserForModalWindow(props.dealer));
        dispatch(modalSetModalAccessDataShowBool(true));
    }
    function openModalAccuratelyRemove() {
        dispatch(modalSetUserForModalWindow(props.dealer));
        dispatch(modalSetTypeDelete("Dealer"));
        dispatch(modalSetModalAccuratelyRemoveShowBool(true));
    }
   
    return(
        <Fragment>
            {console.log('rerender cardDealer')}
            <div className="cardDealer">
                <div className="cardDealer__dataAndRemove">
                    <div>{toDateTime(props.dealer.created)}</div>
                    <div onClick={openModalAccuratelyRemove} className="cardDealer__remove"><SVG src={trash}/></div>
                </div>

                <div className="cardDealer__emailAndpassword">
                    <div className="long-and-truncated">
                        <div className="cardDealer__email long-and-truncated">{props.dealer.email}</div>
                        <div className="cardDealer__password">**************</div>
                    </div>
                    <div onClick={openModalAccessData} className="cardUser__buttonShow"><SVG src={eye}/></div>
                </div>

                <div className="cardDealer__buttonAndCountPhoto">
                    {/* <div onClick={openModalAccessData} className="cardDealer__buttonShow">Показать</div> */}
                    <div className="cardDealer__countPhoto">
                        <span>Всего клиентов:</span>
                        <span className="cardDealer__count">{props.dealer.clientCounter}</span>
                    </div>
                </div>


            </div>
        </Fragment>
    )
});