import { getUserByToken } from '../../../services/userRequestsService';
import { APP_SET_AUTH_USER, APP_SET_PRELOAD } from './appTypes';

export const appSetAuthUser = (authUser) =>{
    return{
        type: APP_SET_AUTH_USER,
        payload:{
            authUser
        }
    }
}

export const appSetPreload = (preload) =>{
    return{
        type: APP_SET_PRELOAD,
        payload:{
            preload
        }
    }
}


export const appAsyncSetAuthUser = () =>{
    return function (dispatch){
        dispatch(appSetPreload(true));
        return getUserByToken()
            .then(response => {
                if (response.data.token === "empty"){
                    console.log(response.data, 'authUserCall');
                    localStorage.setItem('token', "");
                    // dispatch(appSetAuthUser('empty'));
                    setTimeout(() => {
                        dispatch(appSetPreload(false));
                    }, 500);
                }
                else{
                    console.log(response.data, "auth");
                    dispatch(appSetAuthUser(response.data));
                    setTimeout(() => {
                        dispatch(appSetPreload(false));
                    }, 500);
                }
                return response;
            })
            .catch(e => {
                console.log('notAuth');
                console.log(e);
                setTimeout(() => {
                    dispatch(appSetPreload(false));
                }, 500);
                // if (authUser){
                //     console.log("error notAuth");
                // }
            });
    }
}
