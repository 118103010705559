import { PANEL_SET_AVAILABILITY_PHOTO, PANEL_SET_BUTTON_SHOW_ALL, PANEL_SET_CARD_DEALERS_ARRAY, PANEL_SET_CARD_USERS_ARRAY, PANEL_SET_DEALER_SELECT, PANEL_SET_LINK_QR_CODE, PANEL_SET_SEARCH_WORD, PANEL_SET_SET_OF_PHOTOS, PANEL_SET_SPINNER_SMALL } from "../../actions/panel/panelTypes";

const initialState = {
    cardUsersArray: "",
    cardDealersArray:"",
    setOfPhotos: "",
    linkQrCode: "",
    searchWord: "",
    dealerSelect: "all",
    availabilityPhoto: "all",
    spinnerSmall: false,
    buttonShowAll: false
};

export const panelReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case PANEL_SET_CARD_USERS_ARRAY:
            return {...state, cardUsersArray: payload.cardUsersArray };
        case PANEL_SET_CARD_DEALERS_ARRAY:
            return {...state, cardDealersArray: payload.cardDealersArray };
        case PANEL_SET_SET_OF_PHOTOS:
            return {...state, setOfPhotos: payload.setOfPhotos };
        case PANEL_SET_LINK_QR_CODE:
            return {...state, linkQrCode: payload.linkQrCode };
        case PANEL_SET_SEARCH_WORD:
            return {...state, searchWord: payload.searchWord };
        case PANEL_SET_DEALER_SELECT:
            return {...state, dealerSelect: payload.dealerSelect };
        case PANEL_SET_AVAILABILITY_PHOTO:
            return {...state, availabilityPhoto: payload.availabilityPhoto };
        case PANEL_SET_SPINNER_SMALL:
            return {...state, spinnerSmall: payload.spinnerSmall };
        case PANEL_SET_BUTTON_SHOW_ALL:
            return {...state, buttonShowAll: payload.buttonShowAll };
        default:
            return state;
    }
};