import React, {Fragment} from "react";
import "./modalWindowContacts.scss";


import SVG from 'react-inlinesvg';
import times from '../../media/modal/times.svg';
import envelope from '../../media/modal/envelope.svg';
import phoneAlt from '../../media/modal/phone-alt.svg';
import telegram from '../../media/modal/telegram.svg';

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { CSSTransition } from 'react-transition-group';
import { modalSetModalContactsShowBool } from "../../../store/actions/modal/modalActions";


export const ModalWindowContacts = React.memo( function ModalWindowContacts(props){
    const dispatch = useDispatch();

    const modalContactsShowBool = useSelector((state) => state.modal.modalContactsShowBool, shallowEqual);
    const cssTransitionTimeout = useSelector((state) => state.modal.cssTransitionTimeout, shallowEqual);
    

    function closeModalContacts() {
        dispatch(modalSetModalContactsShowBool(false))
    }

    
    return(
        <Fragment>
            {console.log("reload_modalWindowContacts")}
            <CSSTransition
                in={modalContactsShowBool}
                classNames="modalWindowContactsAnim"
                timeout={cssTransitionTimeout}
                mountOnEnter
                unmountOnExit
            >
                {state => 
                    <div className={`modalWindowContactsBG ${state}`}>
                        <div onClick={closeModalContacts} className="modalWindowContactsBG__close">
                            <SVG className="modalWindowContactsBG__close__button" src={times}/>
                        </div>
                        <div className="modalWindowContacts">

                                <span className="modalWindowContacts__header-name">Контактная информация</span>

                                <div className="modalWindowContacts__block">

                                    <div className="modalWindowContacts__info-block">
                                        <SVG src={phoneAlt} />
                                        <div className="modalWindowContacts__phone-text">
                                            <a href="tel:+79888374441">+7 (988) 837-44-41</a>
                                            <a href="tel:+79280700920">+7 (928) 070-09-20</a>
                                            <a href="tel:+79284811155">+7 (928) 481-11-55</a>
                                            <a href="tel:+79188215557">+7 (918) 821-55-57</a>
                                        </div>
                                    </div>

                                    <div className="modalWindowContacts__info-block">
                                        <SVG src={envelope} />
                                        <div className="modalWindowContacts__email-text">
                                            <a href="mailto:gur019@yandex.ru?subject=Заявка">gur019@yandex.ru</a>
                                        </div>
                                    </div>

                                    <div className="modalWindowContacts__info-block">
                                        <SVG src={telegram} />
                                        <div className="modalWindowContacts__telegram-text">
                                            <a href="tg://resolve?domain=@sei66660">@sei66660</a>
                                            <a href="tg://resolve?domain=@gur019">@gur019</a>
                                        </div>
                                    </div>


                                </div>

                                <div className="modalWindowContacts__button-block">
                                    <div onClick={closeModalContacts} className="modalWindowContacts__close-button">Закрыть</div>
                                </div>





                        </div>
                    </div>
                }

            </CSSTransition>
        </Fragment>
    )
});