export function emailHider(email){
    let newStr = "";
    if (email){
        let to = email.search('@'); 
        if (email.length - 1 > to + 1);
        newStr = email.substring(0, to + 1);
    }
    return newStr + "***"
}


export function toDateTime(secs) {
    let masMonthsRus = ["янв","фев","мар","апр","май","июн","июл","авг","сен","окт","ноя","дек"];
    let masMonthsEn = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    var d = new Date(secs);
    //27.01.2022 17:31
    // var datestring = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." +
    //     d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    
    //27 янв 2022 в 17:31
    // var datestring = d.getDate() + " " + masMonthsRus[d.getMonth()] + " " + userYear +
    //     " в " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);

    let actualYear = new Date().getFullYear();
    let userYear = d.getFullYear();
    if (actualYear > userYear){
        var datestring = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." +
        d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    }
    else{
        var datestring = d.getDate() + " " + masMonthsRus[d.getMonth()] +
        " в " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    }
    return datestring;
}


export function toDateDay(secs) {
    let masMonthsRus = ["Янв","Фев","Мар","Апр","Май","Июн","Июл","Авг","Сен","Окт","Ноя","Дек"];
    let masMonthsEn = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    var d = new Date(secs);
    //27.01.2022 17:31
    // var datestring = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." +
    //     d.getFullYear() + " " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);
    
    //27 янв 2022 в 17:31
    // var datestring = d.getDate() + " " + masMonthsRus[d.getMonth()] + " " + userYear +
    //     " в " + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2);

    let actualYear = new Date().getFullYear();
    let userYear = d.getFullYear();
    if (actualYear > userYear){
        var datestring = ("0" + d.getDate()).slice(-2) + "." + ("0"+(d.getMonth()+1)).slice(-2) + "." +
        d.getFullYear();
    }
    else{
        var datestring = d.getDate() + " " + masMonthsRus[d.getMonth()];
    }
    return datestring;
}


export function areTheTwoDaysDifferent (firstTime, secondTime) {
    var d1 = new Date(firstTime);
    var d2 = new Date(secondTime);
    if (d1.getDate() == d2.getDate() && d1.getMonth() + 1 == d2.getMonth() + 1 && d1.getFullYear() == d2.getFullYear()){
        return false;
    }
    else{
        return true;
    }
}


export function checkTimeToDelete(timeCreated, days) {
    var limit = new Date();
    limit.setDate(limit.getDate() - days);
    // console.log(limit.getTime(), 'limit');
    // console.log(timeCreated, 'timeCreated');
    if (timeCreated < limit.getTime()){
        return false;
    }
    else{
        return true;
    }
}