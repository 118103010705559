import React, { Fragment, useRef } from "react";
import './filePhotoByDay.scss';

import { useDispatch } from "react-redux";
import { emailHider, toDateDay, toDateTime } from "../functions";
import { FilePhoto } from "../filePhoto/filePhoto";

export const FilePhotoByDay = React.memo( function FilePhotoByDay(props){
    
   
    return(
        <Fragment>
            {console.log('rerender filePhotoByDay')}
            <div className="file-photo-by-day">
                {/* <div className="file-photo-by-day__title">{props.photos[0]? toDateDay(props.photos[0].created): ""} ({props.photos.length} фото)</div> */}
                <div className="file-photo-by-day__title">{props.photos[0]? toDateDay(props.photos[0].created): ""}</div>
                <div className="file-photo-by-day__filePhoto">
                    {(props.photos || []).map((photo, i) => {
                            
                            return  (   
                                <FilePhoto key = {photo.id}  photo = {photo}/>
                            )
                            
                        })}

                </div>

            </div>
        </Fragment>
    )
});