import React, { Fragment } from "react";
import "./cardUser.scss";

import SVG from 'react-inlinesvg';
import trash from "../../media/panel/trash.svg";
import eye from "../../media/panel/eye.svg";
import { modalSetModalAccessDataShowBool, modalSetModalAccuratelyRemoveShowBool, modalSetModalUserPhotoShowBool, modalSetTypeDelete, modalSetUserForModalWindow } from "../../../store/actions/modal/modalActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { checkTimeToDelete, emailHider, toDateTime } from "../functions";

export const CardUser = React.memo( function CardUser(props){
    const dispatch = useDispatch();

    const authUserRole = useSelector((state) => state.app.authUser.role, shallowEqual);

    function openModalAccessData() {
        dispatch(modalSetUserForModalWindow(props.card));
        dispatch(modalSetModalAccessDataShowBool(true));
    }
    function openModalAccuratelyRemove() {
        dispatch(modalSetUserForModalWindow(props.card));
        dispatch(modalSetTypeDelete("User"));
        dispatch(modalSetModalAccuratelyRemoveShowBool(true));
    }
    function openModalUserPhoto() {
        dispatch(modalSetUserForModalWindow(props.card));
        dispatch(modalSetModalUserPhotoShowBool(true));
    }


   
    return(
        <Fragment>
            {console.log('rerender cardUser')}
            <div className="cardUser">
                <div className="cardUser__dataAndRemove">
                    <div>{toDateTime(props.card.created)}</div>
                    {props.card.photoCounter === 0 && (authUserRole == "Admin" || checkTimeToDelete(props.card.created, 1)) &&
                        <div onClick={openModalAccuratelyRemove} className="cardUser__remove"><SVG src={trash}/></div>
                    }
                </div>

                <div className="cardUser__emailAndpassword">
                    <div className="long-and-truncated">
                        <div className="cardUser__email long-and-truncated">{emailHider(props.card.email)}</div>
                        <div className="cardUser__password">**************</div>
                    </div>
                    <div onClick={openModalAccessData} className="cardUser__buttonShow"><SVG src={eye}/></div>

                </div>

                <div className="cardUser__adminBlock">
                    <div className="cardUser__button-and-count-block">
                        {/* {props.card.photoCounter === 0 ?
                            <div className="cardUser__buttonPhoto cardUser__buttonPhoto_disabled">Показать фото</div>
                        : */}
                            <div onClick={openModalUserPhoto} className="cardUser__buttonPhoto">Показать фото</div>
                        {/* } */}
                        
                        <div className="cardUser__countPhoto">
                            <span>{props.card.photoConfirmCounter}{props.card.photoCounter > props.card.photoConfirmCounter ? "(" + (props.card.photoCounter - props.card.photoConfirmCounter) + ")" : '' } </span>
                        </div>
                    </div>

                    {authUserRole == "Admin" &&
                        <div className="cardUser__nameDealer">{props.card.whoCreated}</div>
                    }
                    
                </div>

            </div>
        </Fragment>
    )
});